export enum RiskRatingsEnum {
  COMPLIANT = 'C',
  NON_COMPLIANT = 'NC',
  NO_DATA = 'ND',
  PARTIAL_DATA = 'PD',
  // TODO: remove above when all compliance labels are aligned
  COMPLIANT2 = 'compliant',
  NON_COMPLIANT2 = 'non-compliant',
  NO_DATA2 = 'no data',
  PARTIAL_DATA2 = 'partial data',
}

