import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class StateService<T> {
  private state$: BehaviorSubject<T>;
  private state: T; // Internal mutable state

  protected get currentState(): T {
    return this.state;
  }

  constructor(initialState: T) {
    this.state = initialState;
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  protected select<K>(mapFn: (state: T) => K): Observable<K> {
    return this.state$.asObservable().pipe(
      map((state: T) => mapFn(state)),
      distinctUntilChanged()
    );
  }

  protected setState(newState: Partial<T>, emit = true ){
    this.state = {
      ...this.state,
      ...newState
    };
    if(emit) {
      this.state$.next(this.state);
    }
  }

   getValue<K extends keyof T>(key?: K): T[K] | T {
    const currentState = this.state$.getValue(); // Get the current state
    
    if (key) {
      return currentState[key]; // Return the specific key value
    }
    
    return currentState; // Return the whole state if no key is provided
  }
}