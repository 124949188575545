import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  PLANTATION_LIST_TABLE_COLUMNS,
  QUESTIONNAIRE_TABLE_COLUMNS
} from 'src/app/constants/table-columns.const';
import {
  catchError,
  combineLatest,
  concatMap,
  filter,
  map,
  of,
  take
} from 'rxjs';
import { PlantationTableTabsEnum } from 'src/app/enums/plantation-table-tabs.enum';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import {
  CrdState,
  Dataset,
  GeometryData,
  INITIAL_CRD_STATE,
  INITIAL_PAGED_STATE,
  Plantation,
  RiskLayer,
  RiskOverlapDetail,
  TotalSummary
} from 'src/app/models/crd-state.interface';
import { GeometryPayload } from 'src/app/models/geometry-payload.model';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import {
  RiskValueTableParams,
  TableParams
} from 'src/app/models/table-params.model';
import { DashboardService } from '../data-service/dashboard.service';
import { MapPageService } from '../data-service/map-page.service';
import { SiDetails } from './../../models/crd-state.interface';
import { EventStateService } from './event-state.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class CrdStateService extends StateService<CrdState> {
  // used in interceptor
  private _crd = '';
  private _plantationTable = '';
  get crd() {
    return this._crd;
  }

  set crd(crd: string) {
    this._crd = crd;
  }

  get plantationTable() {
    return this._plantationTable;
  }

  set plantationTable(plantationTable: string) {
    this._plantationTable = plantationTable;
  }

  selectedPlantationList$ = this.select(
    (state) => state.selectedPlantationList
  );
  checkedSelectedPlantationList$ = this.select(
    (state) => state.checkedSelectedPlantationList
  );
  pagedPlantationList$ = this.select((state) => state.pagedPlantationList);

  // TODO: refactor
  pagedQuestionnaireList$ = this.select(
    (state) => state.pagedQuestionnaireList
  );

  selectedQuestionnaireList$ = this.select(
    (state) => state.selectedQuestionnaireList
  );

  pagedRiskValues$ = this.select((state) => state.pagedRiskValues);
  pagedRiskValuesModal$ = this.select((state) => state.pagedRiskValuesModal);
  plantationListFilters$ = this.select((state) => state.plantationListFilters);
  questionnaireListFilters$ = this.select(
    (state) => state.questionnaireListFilters
  );
  riskOverlapDetails$ = this.select((state) => state.riskOverlapDetails);
  riskLayers$ = this.select((state) => state.riskLayers);
  geometryData$ = this.select((state) => state.geometryData);
  selfOverlapGeometryData$ = this.select(
    (state) => state.selfOverlapGeometryData
  );
  mapStyle$ = this.select((state) => state.mapStyle);
  currentScreen$ = this.select((state) => state.currentScreen);
  geometryDataExport$ = this.select((state) => state.geometryDataExport);
  datasets$ = this.select((state) => state.datasets);
  selectedDataset$ = this.select((state) => state.selectedDataset);
  selectedPeriod$ = this.select((state) => state.selectedPeriod);
  parsedFileData$ = this.select((state) => state.parsedFileData);
  parsedFileErrors$ = this.select((state) => state.parsedFileErrors);
  uploadFileMappingColumns$ = this.select(
    (state) => state.uploadFileMappingColumns
  );
  totalSummary$ = this.select((state) => state.totalSummary);
  riskValueAggregates$ = this.select((state) => state.riskValueAggregates);
  riskProperties$ = this.select((state) => state.riskProperties);
  selectedTab$ = this.select((state) => state.selectedTab);
  tableColumns$ = this.select((state) => state.tableColumns);
  favoritePlantationFilters$ = this.select(
    (state) => state.favoritePlantationFilters
  );
  siDetails$ = this.select((state) => state.siDetails);
  datasetReports$ = this.select((state) => state.datasetReports);
  systemFeature$ = this.select((state) => state.systemFeature);

  constructor(
    private dashboardService: DashboardService,
    private mapPageService: MapPageService,
    private eventStateService: EventStateService,
    private translocoService: TranslocoService
  ) {
    super(INITIAL_CRD_STATE);
  }

  setSelectedTab(selectedTab: PlantationTableTabsEnum) {
    this.setState({ selectedTab });
    if (selectedTab === PlantationTableTabsEnum.PLANTATION) {
      // this.setSelectedTab(PlantationTableTabsEnum.PLANTATION);
      this.setTableColumns(PLANTATION_LIST_TABLE_COLUMNS);
    } else if (selectedTab === PlantationTableTabsEnum.QUESTIONNAIRE) {
      this.setTableColumns(QUESTIONNAIRE_TABLE_COLUMNS);
      // this.tableDataKey = '_id';
    }
  }

  setTableColumns(columns: ColumnDefinition[]) {
    this.setState({ tableColumns: columns });
  }

  setSelectedPlantationList(plantations: Plantation[]) {
    const selectedPlantations = [
      ...plantations.map((plantation) => ({ ...plantation, checked: true }))
    ];
    this.setState({ selectedPlantationList: selectedPlantations });
  }

  setCheckedSelectedPlantationList(plantations: Plantation[]) {
    const selectedPlantations = [
      ...plantations.map((plantation) => ({
        ...plantation
      }))
    ];
    this.setState({ checkedSelectedPlantationList: selectedPlantations });
  }

  setSelectedQuestionnaireList(plantations: Plantation[]) {
    const selectedQuestionnaires = [
      ...plantations.map((plantation) => ({ ...plantation, checked: true }))
    ];
    this.setState({ selectedQuestionnaireList: selectedQuestionnaires });
  }

  setRiskLayers(riskLayers: RiskLayer[]) {
    this.setState({ riskLayers: riskLayers });
  }

  setGeometryData(geometryData: GeometryData[]) {
    this.setState({ geometryData: geometryData });
  }

  setRiskOverlapDetails(riskOverlapDetails: RiskOverlapDetail[]) {
    this.setState({ riskOverlapDetails: riskOverlapDetails });
  }

  setMapStyle(mapStyle: string) {
    this.setState({ mapStyle: mapStyle });
  }

  // TODO: remove conditional rednering related code
  setCurrentScreen(screen: ScreenEnum) {
    this.setState({ currentScreen: screen });
  }

  setSelectedDataset(dataset: Dataset | null) {
    this.setState({ selectedDataset: dataset });
    this.crd = dataset ? dataset.name : '';
    this.plantationTable = dataset ? dataset.default_plantations_table : '';
  }

  setSelectedPeriod(period: string) {
    this.setState({ selectedPeriod: period });
  }

  setSiDetails(siDetails: SiDetails) {
    let parsedSiDetails = siDetails;
    if (siDetails.company) {
      const parsedCompany = JSON.parse((siDetails as any).company);
      const company = {
        type:
          parsedCompany.type === 'Consumer'
            ? 'DASHBOARD.SI_LINKING.CONSUMER'
            : 'DASHBOARD.SI_LINKING.PRODUCER',
        name: parsedCompany.name
      };

      parsedSiDetails = {
        ...siDetails,
        company: company
      };
    }
    this.setState({ siDetails: parsedSiDetails });
  }

  // TODO: update type
  setParsedFileData(data: any) {
    this.setState({ parsedFileData: data });
  }

  resetDashboardStates() {
    this.setState({ pagedPlantationList: INITIAL_PAGED_STATE });
    this.setState({ plantationListFilters: undefined });
    this.eventStateService.isTotalSummaryLoading = true;
  }

  resetRiskProperties() {
    this.setState({ riskProperties: undefined });
  }

  resetUploadFileMappingColumns() {
    this.setState({ uploadFileMappingColumns: undefined });
  }

  resetParsedFileErrors() {
    this.setState({ parsedFileErrors: undefined });
  }

  getGeometryDataExport(plantationCodes: string[]) {
    this.dashboardService
      .getGeometryDataForExport(plantationCodes)
      .subscribe((res) => this.setState({ geometryDataExport: res }));
  }

  getGeometryData(params: GeometryPayload, isSiLinking = false) {
    this.mapPageService
      .getGeometryData(params, isSiLinking)
      .subscribe((res) => {
        this.setState({ geometryData: res });
      });
  }

  // getSelfOverlapGeometryData(plantationCodes: string[]) {
  //   this.mapPageService
  //     .getGeometryData(plantationCodes)
  //     .subscribe((res) => this.setState({ selfOverlapGeometryData: res }));
  // }

  getDatasets() {
    this.dashboardService
      .getDatasets()
      .subscribe((res) => this.setState({ datasets: res }));
  }

  getRiskLayers() {
    return this.mapPageService
      .getRiskLayers()
      .subscribe((riskLayers) => this.setState({ riskLayers: riskLayers }));
  }

  getMapStyle() {
    this.mapPageService
      .getMapStyle()
      .subscribe((mapStyle) => this.setState({ mapStyle: mapStyle }));
  }

  getUploadFileMappingColumns() {
    this.dashboardService
      .getUploadMappingColumns()
      .subscribe((columns) =>
        this.setState({ uploadFileMappingColumns: columns })
      );
  }

  getParsedFileErrors(data: ParsedUploadPlantation[]) {
    this.dashboardService
      .validatePlantationData(this.crd, data)
      .pipe(take(1))
      .subscribe((errors) => {
        this.setState({ parsedFileErrors: errors });
      });
  }

  getPlantationsPaginated(params: TableParams) {
    combineLatest([this.riskProperties$, this.selectedPeriod$])
      .pipe(
        filter(
          ([riskProperties, selectedPeriod]) =>
            !!riskProperties && !!selectedPeriod
        ),
        take(1),
        concatMap(([riskProperties, selectedPeriod]) => {
          if (!riskProperties) return of(null);
          return this.dashboardService
            .getPlantationsPaginated({
              tableParams: { ...params, period: selectedPeriod },
              riskProperties: riskProperties
            })
            .pipe(
              map((plantations) => {
                plantations.results.forEach((plantation: any) => {
                  riskProperties.forEach((prop) => {
                    const riskBoolean = `${prop.name}_value`;
                    if (plantation[riskBoolean]) {
                      plantation.risks?.push(prop);
                    }
                  });
                });
                return plantations;
              })
            );
        })
      )
      .subscribe((plantations) => {
        if (plantations) {
          this.setState({ pagedPlantationList: plantations });
        }
      });
  }

  getQuestionnairesPaginated(params: TableParams) {
    combineLatest([this.riskProperties$, this.selectedPeriod$])
      .pipe(
        filter(
          ([riskProperties, selectedPeriod]) =>
            !!riskProperties && !!selectedPeriod
        ),
        take(1),
        concatMap(([riskProperties, selectedPeriod]) => {
          if (!riskProperties) return of(null);
          return this.dashboardService
            .getQuestionnairesPaginated({
              tableParams: { ...params, period: selectedPeriod },
              riskProperties: riskProperties
            })
            .pipe(
              map((plantations) => {
                plantations.results.forEach((plantation: any) => {
                  riskProperties.forEach((prop) => {
                    const riskBoolean = `${prop.name}_value`;
                    if (plantation[riskBoolean]) {
                      plantation.risks?.push(prop);
                    }
                  });
                });

                return plantations;
              })
            );
        })
      )
      .subscribe((res) => {
        if (res) {
          this.setState({ pagedQuestionnaireList: res });
        }
      });
  }

  getPlantationListFilters() {
    this.selectedPeriod$
      .pipe(
        concatMap((selectedPeriod) => {
          if (!selectedPeriod) return of([null, null]);
          return combineLatest([
            this.dashboardService.getPlantationListFilters({
              date: selectedPeriod
            }),
            this.riskProperties$
          ]);
        })
      )
      .subscribe(([filters, riskProperties]) => {
        if (filters && riskProperties) {
          const filtersWithRisks = {
            risks: riskProperties?.map((risk) => ({
              label: risk.display_name,
              value: { [`${risk.name}_value`]: true },
              iconColor: risk.color
            }))
          };

          filtersWithRisks.risks?.unshift({
            label: this.translocoService.translate('RISK_TYPES.NO_RISKS'),
            value: { has_any_risk_value: false },
            iconColor: ''
          });

          this.setState({
            plantationListFilters: { ...filters, ...filtersWithRisks } as any
          });
        }
      });
  }

  getTotalSummary(params?: any) {
    this.selectedPeriod$
      .pipe(
        take(1),
        concatMap((period) => {
          if (!period || !params) return of([null, null, null]);
          return this.dashboardService.getTotalSummary({
            ...params,
            period: period
          });
        })
      )
      .subscribe(([totalLandAreas, totalPlots, totalMappingEfforts]) => {
        if (totalLandAreas && totalPlots && totalMappingEfforts) {
          const totals: TotalSummary = {
            totalPlots: totalPlots,
            totalLandAreas: totalLandAreas,
            totalMappingEfforts: totalMappingEfforts
          };

          this.setState({ totalSummary: totals });
        }
      });
  }

  // Does not set state - need for invidual risk overlaps
  getPagedRiskValues(params: RiskValueTableParams) {
    // Maps geometry data for hover and zoom plantation implementation
    return this.geometryData$.pipe(
      concatMap((geometryData) =>
        this.mapPageService.getPagedRiskValues(params).pipe(
          map((pagedRiskValues) => {
            const geoMap = new Map<string, string>();

            geometryData.forEach((data) => {
              geoMap.set(data.plantation_code, data._geo as string);
            });
            pagedRiskValues.results.forEach((value) => {
              value['mapData'] = geoMap.get(value.plantation_code);
            });

            return pagedRiskValues;
          })
        )
      )
    );
  }

  // Does not set state - need for invidual risk overlaps
  getRiskValueAggregates(params: {
    plantationCodes: string[];
    riskType: string;
  }) {
    return this.selectedPeriod$.pipe(
      concatMap((period) =>
        this.mapPageService
          .getRiskValueAggregates({
            period: period ?? '',
            plantationCodes: params.plantationCodes,
            riskType: params.riskType
          })
          .pipe(
            map((aggregates) => {
              return aggregates;
            })
          )
      )
    );
  }

  getRiskProperties() {
    this.dashboardService
      .getRiskProperties()
      .pipe(catchError(() => of([])))
      .subscribe((res) => this.setState({ riskProperties: res }));
  }

  getFavoritePlantationFilters() {
    this.dashboardService
      .getFavoritePlantationFilters()
      .subscribe((res) => this.setState({ favoritePlantationFilters: res }));
  }

  getDatasetReports() {
    this.dashboardService
      .getDatasetReports()
      .subscribe((res) => this.setState({ datasetReports: res }));
  }

  getSystemFeature(featureId = 'file_uploader_v2') {
    this.dashboardService
      .getSystemFeature(featureId, this.crd)
      .subscribe((res) => this.setState({ systemFeature: res }));
  }

  clearSystemFeature() {
    this.setState({ systemFeature: undefined });
  }

  clearDatasetReports() {
    this.setState({ datasetReports: [] });
  }

  updateDataset(isSiLinkingPage = false) {
    this.eventStateService.datasetChanged = true;
    this.eventStateService.plantationFiltersPayload = null;
    this.resetDashboardStates();
    this.getPlantationListFilters();
    this.getFavoritePlantationFilters();
    this.clearDatasetReports();
    this.getDatasetReports();
    if (!isSiLinkingPage) {
      this.getTotalSummary();
    }
  }
}
