import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { SETTINGS_LABELS } from 'src/app/constants/settings-labels.const';
import { SettingsModalContent } from 'src/app/models/settings-modal-content.model';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/data-service/settings.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { SettingsPayload } from 'src/app/models/settings.model';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-settings-modal',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, FormsModule, TranslocoPipe],
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {
  get screen() {
    return this.dialogConfig.data;
  }

  isDoNotShowAgain = false;
  isConfirmInProgress = false;
  screens = ScreenEnum;
  modalContent: SettingsModalContent = SETTINGS_LABELS.confirmation;

  homeUrl = '';
  destroyed$ = new Subject<void>();

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private router: Router,
    private crdStateService: CrdStateService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.initializeContent();
    this.constructHomeUrl();
  }

  initializeContent() {
    if (this.screen === ScreenEnum.SETTINGS) {
      this.modalContent = SETTINGS_LABELS.confirmation;
    } else {
      this.modalContent = SETTINGS_LABELS.redirect;
    }
  }

  constructHomeUrl() {
    const currentUrl = this.router.url;
    const parts = currentUrl.split('/');
    parts.pop();
    this.homeUrl = [...parts, 'dashboard', 'plantations'].join('/');
  }

  onBackClicked() {
    this.dynamicDialogRef.close();
  }

  onConfirmClicked() {
    this.isConfirmInProgress = true;
    const settingsValues =
      this.crdStateService.getSavedSettings() as SettingsPayload;
    this.settingsService
      .saveSettings(settingsValues)
      .pipe(
        finalize(() => (this.isConfirmInProgress = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.dynamicDialogRef.close();
        this.router.navigate([this.homeUrl]);
      });
  }
}
