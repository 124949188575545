export const environment = {
  production: false,
  isCn: false,
  name: 'Development',
  mapboxToken:
    'pk.eyJ1IjoiYnJ5YW5oYyIsImEiOiJjbG5lN2hhd3IwZHA0MnNtamFvenpqMjR5In0.v6IJPRIgSL1cjD2WMI75VA',
  CRD_API: 'https://api-traceability-crd-development.agridence.com/api/',
  CRD_API_CN: 'https://api-traceability-crd-development.agridencecn.com/api/',
  TRACEABILITY_API:
    'https://traceability-development.agridence.com/api/v2/traceability/service/',
  TRACEABILITY_API_CN:
    'https://traceability-development.agridencecn.com/api/v2/traceability/service/',
  ARP_API: 'https://api-platform-testing.agridence.com/api/v1/',
  ARP_API_CN: 'https://api-platform-testing.agridencecn.com/api/v1/',
  USER_CONFIG:
    'https://assets.agridence.com/configs/development/{tenantId}/config.json',
  ASSET_API: 'https://www.agridence.github.com/',
  SURVEY_STORAGE_BUCKET:
    'traceability/surveys/{datasetName}/{surveyFrameworkConfigVersion}/{datetime}',
  PLANTATION_UPLOAD_STORAGE_BUCKET:
    'polygon_mapping/file_uploader_v2/crd={dataset}/datetime={datetime}',
  RISK_MITIGATION_UPLOAD_STORAGE_BUCKET:
    'polygon_mapping/risk_mitigations/dataset={dataset_name}/datetime={datetime}',
  firebase: {
    apiKey: 'AIzaSyCCefSqEqw5Vga5YlItyd9vpzmhSlqgZ-0',
    authDomain: 'hcplatform-development.firebaseapp.com',
    projectId: 'hcplatform-development',
    storageBucket: 'gs://agd-temp-assets-development',
    messagingSenderId: '612736903809',
    appId: '1:612736903809:web:74a4ebadab4b59004afb80',
    measurementId: 'G-K2DSKHCGTC'
  }
};
