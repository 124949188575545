/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Observable,
  catchError,
  combineLatest,
  delay,
  finalize,
  map,
  of,
  tap
} from 'rxjs';
import { SURVEY_RISKS_COLUMNS } from 'src/app/constants/table-columns.const';
import { GeoJsonTypesEnum } from 'src/app/enums/geojson-types.enum';
import {
  RiskReportDownload,
  RiskReportRequest
} from 'src/app/models/async-risk-report.model';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import {
  Dataset,
  DatasetReport,
  FavoriteFilter,
  GeoJsonType,
  Plantation,
  PlantationTableFilters,
  RiskProperty,
  SurveyRisk,
  SurveyRiskUpdated,
  SystemFeature,
  TotalLandArea,
  TotalMappingEffort,
  TotalPlot
} from 'src/app/models/crd-state.interface';
import { DeletePlantationPayload } from 'src/app/models/delete-plantation-payload.model';
import { DynamicDownloadPayload } from 'src/app/models/download-risk-report-payload.model';
import {
  AddFavoriteFilterPayload,
  DeleteFavoriteFilterPayload
} from 'src/app/models/favorite-filters-payload.model';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import { SiLinkingPlantationsPayload } from 'src/app/models/si-linking-payload.model';
import { TableParams } from 'src/app/models/table-params.model';
import { environment } from 'src/environments/environment';
import { PagedResponse } from '../../models/paged-response.model';
import { EventStateService } from '../state-service/event-state.service';
import { UtilityService } from '../utility.service';
import { RiskRatingsEnum } from 'src/app/enums/risk-ratings.enum';
import { ProcessPlantationDataResponse } from 'src/app/models/process-plantation-data-response.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private eventStateService: EventStateService,
    private utilityService: UtilityService,
    private decimalPipe: DecimalPipe,
    private translocoService: TranslocoService
  ) {}

  getGeometryDataForExport(
    plantationCodes: string[]
  ): Observable<Plantation[]> {
    return this.http.get<Plantation[]>(
      `${
        environment.CRD_API
      }v1/plantations/download/${this.utilityService.constructMultiplePlantationsQuery(
        plantationCodes
      )}`
    );
  }

  // Async download - returns message and taskId for polling
  requestFullRiskReport(params: DynamicDownloadPayload) {
    this.eventStateService.downloadFullRiskReportLoading = true;
    const filters = structuredClone(params.filters);
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          // TODO: Commented all risk rating related for 11/4 release
          // 'if(ifnull((select max(risk_score) from unnest(survey_risks)), -2) = -2, "ND", if((select max(risk_score) from unnest(survey_risks)) > 0 or ifnull(has_any_risk_value, false) = true, "NC", "C")) as `Risk Rating`',
          'country as Country',
          'adm_1 as Province',
          'adm_2 as District',
          'adm_3 as `Sub District`',
          'adm_4 as Village',
          'date_created as `Date Created`',
          'data_source as `Data Source`',
          'field_team_code as `Field Team Code`',
          'survey_risks',
          'area_calculated as `Area`',
          ...params.riskColumns!
        ]
      }
    };

    return this.http.post<RiskReportRequest>(
      `${environment.CRD_API}v2/plantations/download-risks/job/?date=${params.period}${orderBy}`,
      { ...columns, ...filters }
    );
  }

  downloadFullRiskReport(taskId: string) {
    return this.http.get<RiskReportDownload>(
      `${environment.CRD_API}v2/plantations/download-risks/job/?task_id=${taskId}`
    );
  }

  requestDatasetReport(params: DynamicDownloadPayload) {
    this.eventStateService.setDatasetReportLoadingState(params.reportId!, true);
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';
    const filters = structuredClone(params.filters);
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          // TODO: Commented all risk rating related for 11/4 release
          // 'if(ifnull((select max(risk_score) from unnest(survey_risks)), -2) = -2, "ND", if((select max(risk_score) from unnest(survey_risks)) > 0 or ifnull(has_any_risk_value, false) = true, "NC", "C")) as `Risk Rating`',
          'country as Country',
          'adm_1 as Province',
          'adm_2 as District',
          'adm_3 as `Sub District`',
          'adm_4 as Village',
          'field_team_code as `Field Team Code`',
          'survey_risks',
          'area_calculated as `Area`',
          ...params.riskColumns!
        ]
      }
    };

    return this.http
      .post<RiskReportRequest>(
        `${environment.CRD_API}v2/dataset/reports/${params.reportId}/job?date=${params.period}${orderBy}`,
        { ...columns, ...filters }
      )
      .pipe(map((res) => ({ ...res, report_id: params.reportId })));
  }

  downloadDatasetReport(taskId: string, reportId: string) {
    return this.http.get<RiskReportDownload>(
      `${environment.CRD_API}v2/dataset/reports/job?task_id=${taskId}&report_id=${reportId}`
    );
  }

  getDatasetReports() {
    return this.http
      .get<DatasetReport[]>(`${environment.CRD_API}v2/dataset/reports`)
      .pipe(
        map((reports) => {
          return reports;
        })
      );
  }

  // TODO: refactor - simplify method for dynamic downloads
  downloadMappingDataQualityReport(params: DynamicDownloadPayload) {
    this.eventStateService.downloadMappingDataQualityReportLoading = true;
    const filters = structuredClone(params.filters);
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          'risk_self_overlap_v1_overlap_area as `Risk Plantation Overlaps Ha Overlap`',
          'risk_self_overlap_v1_overlap_area_perc as `Risk Plantation Overlaps Percentage Overlap`',
          '(select string_agg(plantation_name) from unnest(risk_self_overlap_v1_data)) as `Overlapping Plantations`',
          '(select string_agg(overlapping_plantation_code) from unnest(risk_self_overlap_v1_data)) as `Overlapping Plantation Codes`'
        ]
      }
    };
    filters!['AND']['risk_self_overlap_v1_value'] = [true];

    return this.http.post<Blob>(
      `${environment.CRD_API}v2/plantations/download-risks/?date=${params.period}${orderBy}`,
      { ...columns, ...filters },
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  downloadPlantationInformation(params: DynamicDownloadPayload) {
    this.eventStateService.downloadPlantationInformationLoading = true;
    const plantationCodes = params?.plantationCodes;
    const filters = structuredClone(params.filters);
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code as `Plantation Code`',
          'plantation_name as `Plantation Name`',
          'area_calculated as `Land Area`',
          'country as Country',
          'adm_1 as Province',
          'adm_2 as District',
          'adm_3 as `Sub District`',
          'adm_4 as Village',
          'date_created as `Date Created`',
          'data_source as `Data Source`',
          'field_team_code as `Field Team Code`',
          'ST_ASGEOJSON(_geo) as Geometry'
        ]
      }
    };

    if (plantationCodes?.length) {
      filters!['AND']['plantation_code'] = plantationCodes;
    }

    return this.http.post<Blob>(
      `${environment.CRD_API}v2/plantations/download-risks/?date=${params.period}`,
      { ...columns, ...filters },
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  getDatasets(): Observable<Dataset[]> {
    return this.http.get<Dataset[]>(`${environment.CRD_API}v1/datasets/`);
  }

  getPlantationsPaginated(params: {
    tableParams: TableParams;
    riskProperties: RiskProperty[];
  }): Observable<PagedResponse<Plantation>> {
    const orderBy = params.tableParams?.orderBy
      ? `&order_by=${params.tableParams.orderBy}`
      : '';
    const riskColumn = params.riskProperties.map(
      (prop) => `ifnull(${prop.name}_value, false) as ${prop.name}_value`
    );
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code',
          'plantation_name',
          'area_calculated',
          'country',
          'adm_1',
          'adm_2',
          'adm_3',
          'adm_4',
          'date_created',
          'data_source',
          'field_team_code',
          '_docid',
          '_geo_type as geoJsonType',
          // TODO: Commented all risk rating related for 11/4 release
          // 'if(ifnull((select max(risk_score) from unnest(survey_risks)), -2) = -2, "ND", if((select max(risk_score) from unnest(survey_risks)) > 0 or ifnull(has_any_risk_value, false) = true, "NC", "C")) as risk_rating',
          'ifnull(has_any_risk_value, false) as has_any_risk_value',
          ...riskColumn
        ]
      }
    };
    return this.http
      .post<PagedResponse<Plantation>>(
        `${environment.CRD_API}v2/plantations/?date=${params.tableParams.period}&page=${params.tableParams.page}&page_size=${params.tableParams.pageSize}${orderBy}`,
        { ...columns, ...params.tableParams.filters }
      )
      .pipe(
        map((res) => {
          const results = res.results.map((result) => {
            const formattedDateCreated = this.datePipe.transform(
              result.date_created,
              'MM/dd/yyyy h.mm a'
            )!;
            const formattedLandArea = this.decimalPipe.transform(
              result.area_calculated,
              '1.3-3'
            )!;
            const geoJsonType: GeoJsonType =
              this.utilityService.getGeoJsonTypeProps(
                result.geoJsonType as GeoJsonTypesEnum
              );

            return {
              ...result,
              geoJsonType: geoJsonType,
              date_created: formattedDateCreated,
              area_calculated: formattedLandArea,
              isExpanded: false,
              checked: false,
              disabled: false,
              risk_rating: this.utilityService.getComplianceProps(
                result?.risk_rating as RiskRatingsEnum
              ),
              risks: []
            };
          });
          return { ...res, results };
        }),
        tap(() => {
          this.eventStateService.isDashboardTableLoading = false;
        })
      );
  }

  getQuestionnairesPaginated(params: {
    tableParams: TableParams;
    riskProperties?: RiskProperty[];
  }): Observable<PagedResponse<Plantation>> {
    const orderBy = params.tableParams?.orderBy
      ? `&order_by=${params.tableParams.orderBy}`
      : '';
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'plantation_code',
          'plantation_name',
          '_geo_type as geoJsonType',
          '_docid',
          'survey_risk_calculated_at',
          'data_source',
          'survey_risks',
          'country'
          // TODO: Commented all risk rating related for 11/4 release
          // 'if(ifnull((select max(risk_score) from unnest(survey_risks)), -2) = -2, "ND", if((select max(risk_score) from unnest(survey_risks)) > 0 or ifnull(has_any_risk_value, false) = true, "NC", "C")) as risk_rating'
        ]
      }
    };

    return this.http
      .post<PagedResponse<Plantation>>(
        `${environment.CRD_API}v2/plantations/?date=${params.tableParams.period}&page=${params.tableParams.page}&page_size=${params.tableParams.pageSize}${orderBy}`,
        { ...columns, ...params.tableParams.filters }
      )
      .pipe(
        map((response) => {
          const pagedQuestionnaireList = response;

          if (
            pagedQuestionnaireList.results &&
            pagedQuestionnaireList.results.length
          ) {
            pagedQuestionnaireList.results = pagedQuestionnaireList.results.map(
              (result) => {
                // Clone the existing result into updatedResult
                let updatedResult: Plantation = { ...result };

                const surveyColumns = SURVEY_RISKS_COLUMNS;
                const survey_risks_updated: SurveyRiskUpdated = {};

                let overAllSurveyMaxRiskScoreRating = -Infinity;
                let overAllSurveyMaxRiskRating: SurveyRisk | null = null;

                surveyColumns.forEach((column) => {
                  // Find the survey risk with the highest risk_score for the current column.field
                  const maxRisk = (result.survey_risks || [])
                    .filter((r: SurveyRisk) => `${r.category}` === column.field) // Filter risks that match the column field
                    .reduce(
                      (max: SurveyRisk, current: SurveyRisk) =>
                        current.risk_score > max.risk_score ? current : max,
                      { category: '', risk_score: -Infinity } as SurveyRisk // Initialize max with an invalid SurveyRisk
                    );

                  // If no risk was found (i.e., if no valid category matches), set it to undefined
                  survey_risks_updated[column.field] =
                    maxRisk && maxRisk.risk_score !== -Infinity
                      ? maxRisk
                      : null;

                  // Update globalMaxRiskScore if the current column has a higher risk score
                  if (maxRisk.risk_score > overAllSurveyMaxRiskScoreRating) {
                    overAllSurveyMaxRiskScoreRating = maxRisk.risk_score;
                    overAllSurveyMaxRiskRating = maxRisk;
                  }

                  // Set color based on the risk score (assuming getColorForScore is defined elsewhere)
                  survey_risks_updated[`${column.field}_color`] =
                    this.utilityService.getRiskScoreProps(
                      survey_risks_updated[column.field]
                    ).color;
                });

                // Check if overAllSurveyMaxRiskScoreRating is -Infinity, if so, set it to null
                const normalizedMaxRiskScoreRating =
                  overAllSurveyMaxRiskScoreRating === -Infinity
                    ? null
                    : overAllSurveyMaxRiskScoreRating;

                // Get the risk score properties only once
                const riskScoreProps = this.utilityService.getRiskScoreProps({
                  risk_score: normalizedMaxRiskScoreRating
                });

                // Merge updated survey risks into the result
                updatedResult = {
                  ...updatedResult,
                  survey_risks_updated,
                  overAllSurveyMaxRiskRatingProps: {
                    overAllSurveyMaxRiskScoreRating:
                      normalizedMaxRiskScoreRating,
                    overAllSurveyMaxRiskRating,
                    overAllSurveyMaxRiskRatingLabel: riskScoreProps.label,
                    overAllSurveyMaxRiskRatingColor: riskScoreProps.color
                  },
                  risk_rating: this.utilityService.getComplianceProps(
                    result?.risk_rating as RiskRatingsEnum
                  )
                };

                const geoJsonType: GeoJsonType =
                  this.utilityService.getGeoJsonTypeProps(
                    result.geoJsonType as GeoJsonTypesEnum
                  );
                updatedResult.geoJsonType = geoJsonType;

                return updatedResult as Plantation;
              }
            );
          }
          return pagedQuestionnaireList;
        }),
        tap(() => {
          this.eventStateService.isDashboardTableLoading = false;
        })
      );
  }

  getPlantationListFilters(filter: {
    datasetName?: string;
    date: string;
  }): Observable<PlantationTableFilters> {
    let params = new HttpParams();

    // Conditionally add parameters if they are present
    if (filter.datasetName) {
      params = params.set('crd', filter.datasetName);
    }

    if (filter.date) {
      params = params.set('date', filter.date);
    }

    return this.http
      .get(`${environment.CRD_API}v2/plantations/filters/`, { params })
      .pipe(
        map((filters) => {
          return {
            ...filters,
            risk_rating: [
              this.translocoService.translate('DASHBOARD.COMPLIANT'),
              this.translocoService.translate('DASHBOARD.NON_COMPLIANT'),
              this.translocoService.translate('DASHBOARD.NO_DATA')
            ]
          };
        }),
        map((filters) => this.transformFilterData(filters)),
        tap(() => (this.eventStateService.isPlantationFilterLoading = false))
      );
  }

  transformFilterData(data: any): any {
    const transformed: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        transformed[key] = data[key].map((item: string) => {
          const geojsonProps = this.utilityService.getGeoJsonTypeProps(
            item as any
          );

          const value =
            key === 'risk_rating'
              ? this.utilityService.getRiskRatingFilterValue(item)
              : item;

          const filterObject = {
            label: key === '_geo_type' ? geojsonProps.label : item,
            value: value,
            icon: key === '_geo_type' ? geojsonProps.icon : null,
            type: key === '_geo_type' ? 'geojson' : null
          };

          return filterObject;
        });
      }
    }

    if (transformed['_geo_type']) {
      transformed['plantation_name'] = transformed['_geo_type'];
    }

    if (transformed['risk_rating']) {
      transformed['has_any_risk_value'] = transformed['risk_rating'];
    }

    return transformed;
  }

  getTotalSummary(params: any) {
    return combineLatest([
      this.getTotalLandAreas(params),
      this.getTotalPlots(params),
      this.getTotalMappingEfforts(params)
    ]).pipe(
      tap(() => {
        this.eventStateService.isStatisticsUpdating = false;
        this.eventStateService.isTotalSummaryLoading = false;
      }),
      catchError(() => [])
    );
  }

  getTotalLandAreas(params: any): Observable<TotalLandArea[]> {
    const columns = {
      columns: {
        schema_version: 'v1',
        columns: [
          'country',
          'ROUND(SUM(area_calculated), 6) AS area',
          'COUNT(1) as totalCount',
          'round(sum(area_calculated)*1.2, 2) as totalYield '
        ]
      }
    };
    return this.http.post<TotalLandArea[]>(
      `${environment.CRD_API}v2/statistics/land-area/total/?date=${params.period}`,
      { ...columns, ...params.filters }
    );
  }

  getTotalPlots(params: any): Observable<TotalPlot[]> {
    return this.http
      .post<TotalPlot[]>(
        `${environment.CRD_API}v2/statistics/plots/total/?date=${params.period}`,
        params.filters
      )
      .pipe(
        map((res) => {
          res.forEach((plot) => {
            const props = this.utilityService.getGeoJsonTypeProps(
              plot.geometry_type as GeoJsonTypesEnum
            );
            plot.label = props.label;
            plot.icon = props.icon;
          });
          return res;
        })
      );
  }

  getTotalMappingEfforts(params: any): Observable<TotalMappingEffort[]> {
    const payload = {
      aggregates: [
        {
          key: '1',
          function: 'count',
          alias: 'plantationsMapped'
        },
        {
          key: 'area_calculated',
          function: 'SUM'
        }
      ],
      GROUP_BY: ['data_source', 'date_trunc(date_created, DAY) as day']
    };
    return this.http
      .post<TotalMappingEffort[]>(
        `${environment.CRD_API}v2/statistics/dynamic/stats/?date=${params.period}&order_by=day`,
        { ...payload, ...params.filters }
      )
      .pipe(
        map((res) => {
          const groupedData = res.reduce((acc: any, curr: any) => {
            const day = curr.day;
            if (!acc[day]) {
              acc[day] = {
                day: day,
                plantationsMapped: 0,
                totalArea: 0,
                data_source: new Set()
              };
            }
            acc[day].plantationsMapped += curr.plantationsMapped;
            acc[day].totalArea += curr.area_calculated_sum;
            if (curr.data_source) {
              acc[day].data_source.add(curr.data_source);
            }
            return acc;
          }, {}) as TotalMappingEffort[];

          const result = Object.values(groupedData).map(
            (entry: TotalMappingEffort) => ({
              day: entry.day,
              plantationsMapped: entry.plantationsMapped,
              totalArea: entry.totalArea,
              data_source: Array.from(entry.data_source).join(', ')
            })
          );

          return result;
        })
      );
  }

  getRiskProperties(): Observable<RiskProperty[]> {
    return this.http.get<RiskProperty[]>(
      `${environment.CRD_API}v2/datasets/risks/`
    );
  }

  getUploadMappingColumns() {
    return this.http.get(`${environment.CRD_API}v1/file-upload/columns/`).pipe(
      delay(2000),
      // TODO: update type
      map((res: any) => {
        this.eventStateService.matchColumnsLoading = false;
        const mappedColumns: ColumnDefinition[] = [
          ...res.columns.map((data: any, index: any) => ({
            id: ++index,
            field: data.column,
            displayName: data.display,
            editable: true,
            style: `width: ${this.getColumnWidth(data.column)}`
          }))
        ];
        mappedColumns.push({
          id: 6,
          field: 'error',
          displayName: 'Error',
          hidden: true,
          style: 'width: 30rem;',
          sortable: true
        });
        mappedColumns.push({
          id: 7,
          field: 'actions',
          displayName: 'Actions',
          style: 'width: 10rem;'
        });

        return mappedColumns;
      })
    );
  }

  validatePlantationData(
    dataset: string,
    parsedData: ParsedUploadPlantation[]
  ) {
    return this.http
      .post(`${environment.CRD_API}v1/plantation-data/validate/`, {
        dataset: dataset,
        plantation_data: parsedData
      })
      .pipe(
        tap(() => {
          this.eventStateService.validationLoading = false;
        })
      );
  }

  uploadPlantationData(dataset: string, data: ParsedUploadPlantation[]) {
    return this.http
      .post(`${environment.CRD_API}v1/plantation-data/upload/`, {
        dataset: dataset,
        plantation_data: data
      })
      .subscribe(() => {
        this.eventStateService.closePlantationUploadModal = true;
        this.eventStateService.isImportInProgress = false;
        this.utilityService.emitToast({
          isSuccess: true,
          message: 'File is processing..'
        });
      });
  }

  deletePlantation(
    payload: DeletePlantationPayload,
    deleteModal: DynamicDialogRef
  ) {
    const queryParams =
      `?plantation_table=${payload.plantation_table}` +
      `&plantation_code=${payload.plantation_code}` +
      `&dataset=${payload.dataset}`;
    return this.http
      .delete(`${environment.CRD_API}v1/plantations/${queryParams}`)
      .subscribe({
        next: () => {
          this.eventStateService.refreshTable = true;
          this.utilityService.emitToast({
            isSuccess: true,
            message: 'Plantation deleted successfully'
          });
        },
        complete: () => {
          this.eventStateService.isDeletePlantationLoading = false;
          deleteModal.close();
        }
      });
  }

  addFavoriteFilter(payload: AddFavoriteFilterPayload) {
    return this.http.post(
      `${environment.CRD_API}v2/filters/favorites/?dataset=${payload.dataset}`,
      payload
    );
  }

  deleteFavoriteFilter(payload: DeleteFavoriteFilterPayload) {
    const queryParams =
      `?filter_id=${payload.filter_id}` + `&dataset=${payload.dataset}`;
    return this.http.delete(
      `${environment.CRD_API}v2/filters/favorites/${queryParams}`
    );
  }

  getFavoritePlantationFilters(): Observable<FavoriteFilter[]> {
    return this.http.get<FavoriteFilter[]>(
      `${environment.CRD_API}v2/filters/favorites/`
    );
  }

  linkPlantationToSi(payload: SiLinkingPlantationsPayload): Observable<any> {
    return this.http
      .post(
        `${environment.TRACEABILITY_API}si/link-plantations-to-si/`,
        payload
      )
      .pipe(
        tap(() => {
          // this.eventStateService.isLinkPlantationLoading = false;
          // this.eventStateService.isPlantationLinked = true;
          // this.utilityService.emitToast({
          //   message: 'Linked successfully.',
          //   isSuccess: true,
          // });
        })
      );
  }

  getSystemFeature(featureId: string, crd: string): Observable<SystemFeature> {
    this.eventStateService.isSystemFeatureLoading = true;
    const headers = new HttpHeaders().set('skip-error-interceptor', 'true');

    return this.http
      .get<SystemFeature>(
        `${environment.CRD_API}v2/features/${featureId}/status?crd=${crd}`,
        { headers }
      )
      .pipe(
        finalize(() => (this.eventStateService.isSystemFeatureLoading = false))
      );
  }

  processPlantationData(
    dataset: string,
    assetUri: string
  ): Observable<ProcessPlantationDataResponse> {
    return this.http.post<ProcessPlantationDataResponse>(
      `${environment.CRD_API}v2/plantation-data/process?dataset=${dataset}`,
      { asset_uri: assetUri }
    );
  }

  private getColumnWidth(field: string) {
    switch (field) {
      case 'geometry':
        return '30rem';
    }
    return '10rem';
  }
}
