import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from './services/auth.service';
import { FirebaseService } from './summary-dashboard/file/firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService
  ) {}


  ngOnInit(): void {
    this.authService.isFirebaseAuthTokenExpired$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe((isExpired: boolean) => {
      if (isExpired && this.authService.firebaseToken) {
        this.firebaseService.signInWithCustomTokenFirebase(this.authService.firebaseToken as string);
      }
    });
  }

  ngOnDestroy(): void {

    sessionStorage.removeItem('ARP_FirebaseToken');
    sessionStorage.removeItem('ARP_FirebaseAuthTokenExpiry');

    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
