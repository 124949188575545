import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { PrimengExportsModule } from "../primeng-exports.module";
import { FormsModule } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { AuthService } from "../services/auth.service";
import { UploadService } from "../services/offplatform/upload.service";
import { CrdStateService } from "../services/state-service/crd-state.service";
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { StepsModule } from 'primeng/steps';
import { UploadedFile, UploadProgress, DEFAULT_UPLOAD_PROGRESS, UploadStatus, ErrorCount, ValidatedAsset, AssetValidationStatus, ValidationProgress, DEFAULT_VALIDATION_PROGRESS } from "../models/upload.model";
import { finalize, firstValueFrom } from 'rxjs';
import * as XLSX from 'xlsx';
import { of, interval, switchMap, takeWhile, catchError } from 'rxjs';

@Component({
  selector: "app-offplatform-portal",
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    PrimengExportsModule,
    FormsModule,
    ConfirmDialogModule,
    ToastModule,
    StepsModule,
  ],
  providers: [ConfirmationService, MessageService],
  templateUrl: "./offplatform-portal.component.html",
  styleUrls: ["./offplatform-portal.component.scss"],
})
export class OffplatformPortalComponent {
  siNumber: any;
  siDetailId: any;
  secretKey: string = "";
  nextPage = false;
  uploadedFiles: UploadedFile[] = [];
  authSubscription: any;
  tokenCheckInterval: any;
  isLoading: boolean = false;
  isLoadingFiles: boolean = false;
  uploadFiles: boolean = true;
  items: MenuItem[] = [];
  currentStep: number = 0;
  readonly UploadStatus = UploadStatus;
  uploadProgress: UploadProgress = { ...DEFAULT_UPLOAD_PROGRESS };
  validatedAssets: ValidatedAsset[] = [];
  validationProgress: ValidationProgress = { ...DEFAULT_VALIDATION_PROGRESS };

  constructor(
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    public authService: AuthService,
    private uploadService: UploadService,
    private crdStateService: CrdStateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.resetPortalState();
    this.updateStepperStyles();
  }

  private updateStepperStyles() {
    this.items = [
      {
        label: this.translocoService.translate('EXTERNAL_PAGE.STEPS.UPLOAD_VERIFY'),
        icon: '1',
        styleClass: 'completed-step'
      },
      {
        label: this.translocoService.translate('EXTERNAL_PAGE.STEPS.LEGALITY_CHECKS'),
        icon: '2',
        styleClass: this.currentStep >= 1 ? 'completed-step' : ''
      },
      {
        label: this.translocoService.translate('EXTERNAL_PAGE.STEPS.MITIGATION_EVIDENCES'),
        icon: '3',
        disabled: true
      },
      {
        label: this.translocoService.translate('EXTERNAL_PAGE.STEPS.SUMMARY'),
        icon: '4',
        disabled: true
      }
    ];
  }

  checkValidity() {
    this.nextPage = !this.nextPage;
    this.authService.externalToken = this.secretKey;
  }

  logout() {
    this.resetPortalState();
    this.authService.clearSupplierPortalAuthSettings();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.add("drag-over");
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.remove("drag-over");
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    const maxSizeInMB = 15;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert(
          this.translocoService.translate(
            'EXTERNAL_PAGE.FILE_TOO_LARGE',
            { filename: file.name, maxSizeInMB }
          )
        );
        event.target.value = "";
        return;
      }

      if (
        !file.name.toLowerCase().endsWith(".json") &&
        !file.name.toLowerCase().endsWith(".geojson")
      ) {
        alert(
          this.translocoService.translate(
            'EXTERNAL_PAGE.INVALID_GEOJSON_FILE',
            { filename: file.name }
          )
        );
        event.target.value = "";
        return;
      }
    }

    this.handleFiles(files);
  }

  private isValidGeoJSON(content: any): boolean {
    try {
      if (!content.type || !content.features) {
        return false;
      }

      if (content.type !== "FeatureCollection") {
        return false;
      }

      if (!Array.isArray(content.features)) {
        return false;
      }
      return content.features.every(
        (feature: any) =>
          feature.type === "Feature" && feature.geometry && feature.properties
      );
    } catch (error) {
      return false;
    }
  }

  private resetPortalState(): void {
    this.isLoadingFiles = false;
    this.isLoading = false;
    
    this.uploadedFiles = [];
    this.validatedAssets = [];
    
    this.uploadProgress = { ...DEFAULT_UPLOAD_PROGRESS };
    this.validationProgress = { current: 0, total: 0, successCount: 0 };
    
    this.currentStep = 0;
    this.updateStepperStyles();
    
    this.nextPage = false;
  }

  private updateFileStatus(
    file: UploadedFile, 
    status: UploadStatus, 
    errorKey?: string, 
    downloadUrl?: string
  ): void {
    file.status = status;
    
    if (status === UploadStatus.ERROR) {
      const index = this.uploadedFiles.indexOf(file);
      if (index > -1) {
        this.uploadedFiles.splice(index, 1);
        this.uploadProgress.total--;
      }
    } else if (status === UploadStatus.COMPLETED && downloadUrl) {
      file.downloadUrl = downloadUrl;
      this.uploadProgress.successCount++;
    }
  }

  private async handleDuplicateFile(currentFile: UploadedFile): Promise<boolean> {
    const confirmed = await new Promise<boolean>(resolve => {
      this.confirmationService.confirm({
        message: this.translocoService.translate(
          'EXTERNAL_PAGE.DUPLICATE_FILE_MSG',
          { filename: currentFile.filename }
        ),
        header: this.translocoService.translate(
          'EXTERNAL_PAGE.DUPLICATE_FILE'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => resolve(true),
        reject: () => resolve(false)
      });
    });

    if (confirmed) {
      const nameWithoutExt = (currentFile.filename || currentFile.file.name).replace('.geojson', '');
      const newName = `${nameWithoutExt}_${Date.now()}.geojson`;
      const newFile = new File([currentFile.file], newName, { type: currentFile.file.type });
      currentFile.file = newFile;
      currentFile.filename = newName;
    }

    return confirmed;
  }

  private async processExcelFile(url: string): Promise<ErrorCount[]> {
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
      const data = new Uint8Array(buffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      
      const rows = XLSX.utils.sheet_to_json(firstSheet);

      const errorMap = new Map<string, number>();
      
      (rows as { [key: string]: any }[]).forEach((row: { [key: string]: any }) => {
        if (row['errors']) {
          const errors = JSON.parse(row['errors'].replace(/'/g, '"'));
          errors.forEach((error: { text: string }) => {
            errorMap.set(error.text, (errorMap.get(error.text) || 0) + 1);
          });
        }
      });

      return Array.from(errorMap.entries()).map(([text, count]) => ({ text, count }));
    } catch (error) {
      console.error('Error processing Excel file:', error);
      return [];
    }
  }

  private pollWorkflowStatus(jobId: string, asset: ValidatedAsset): Promise<void> {
    return new Promise((resolve) => {
      const assetStatus = this.uploadService.getWorkflowStatus(jobId, this.crdStateService.crd);
      
      assetStatus
        .pipe(
          switchMap((res) => {
            if (res?.result?.completed || res?.status === "FAILED") {
              return of(res);
            }
            return interval(5000).pipe(
              switchMap(() => assetStatus),
              takeWhile(
                (pollingRes) => {
                  const isComplete = pollingRes?.status === "SUCCEEDED" && pollingRes?.result?.completed === true;
                  const isFailed = pollingRes?.status === "FAILED";
                  return !isComplete && !isFailed;
                },
                true
              ),
              catchError((error) => {
                this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.NETWORK_ERROR'));
                throw error;
              })
            );
          }),
          finalize(() => {
            if (asset.status === 'VALIDATING') {
              this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.VALIDATION_TIMEOUT'));
            }
            resolve();
          })
        )
        .subscribe({
          next: (res) => {
            if (res.status === "FAILED") {
              this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.VALIDATION_FAILED'));
            } else if (res.result?.completed && res.status === 'SUCCEEDED') {
              if (res.result.asset_uri) {
                this.updateAssetStatus(asset, 'COMPLETED', undefined, res.result.asset_uri);
              } else {
                this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.NO_ASSET_URI'));
              }
            }
          },
          error: (error) => {
            this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.VALIDATION_FAILED'));
          }
        });
    });
  }

  private async validateAndUploadFile(currentFile: UploadedFile): Promise<void> {
    try {
      const content = await this.readJsonFile(currentFile.file);
      
      const response = await firstValueFrom(this.uploadService.validateDataPlantation({
        dataset: this.crdStateService.crd,
        filename: currentFile.filename || currentFile.file.name,
        profile: "agd_eu_v2025_1",
        plantation_data_geojson: content,
        tenant: this.authService.tenantId || ''
      }));

      if (response.sessionId) {
        this.updateFileStatus(currentFile, UploadStatus.COMPLETED, undefined, response.downloadUrl);
        if (response.downloadUrl) {
          try {
            const errorCounts = await this.processExcelFile(response.downloadUrl);
            if (errorCounts.length > 0) {
              currentFile.errorCounts = errorCounts;
            }
          } catch (excelError) {
            console.error('Error processing Excel file:', excelError);
          }
        }
      } else {
        this.updateFileStatus(currentFile, UploadStatus.ERROR, 'EXTERNAL_PAGE.INVALID_SESSION_ID');
        throw new Error('Invalid session ID');
      }
    } catch (error: unknown) {
      if (error instanceof TypeError || (error as any).status === 0) {
        this.updateFileStatus(currentFile, UploadStatus.ERROR, 'EXTERNAL_PAGE.NETWORK_ERROR');
      } else if ((error as any).status === 400) {
        this.updateFileStatus(currentFile, UploadStatus.ERROR, 'EXTERNAL_PAGE.VALIDATION_ERROR');
      } else {
        this.updateFileStatus(currentFile, UploadStatus.ERROR, 'EXTERNAL_PAGE.FAILED_FILE_PROCESS');
      }
      throw error;
    }
  }

  async handleFiles(files: FileList) {
    this.isLoadingFiles = true;
    const startingIndex = this.uploadedFiles.length;
    const newFiles: UploadedFile[] = [];

    for (const file of Array.from(files)) {
      try {
        const content = await this.readJsonFile(file);
        if (content) {
          newFiles.push({
            file,
            status: UploadStatus.PENDING,
            filename: file.name
          });
        }
      } catch (error) {
        console.error(`Invalid file ${file.name}:`, error);
      }
    }

    this.uploadedFiles.push(...newFiles);
    
    this.uploadProgress = {
      current: 0,
      total: newFiles.length,
      successCount: 0
    };

    try {
      for (let i = 0; i < newFiles.length; i++) {
        const currentFile = newFiles[i];
        this.uploadProgress.current = i + 1;

        const duplicateIndex = this.uploadedFiles.findIndex(
          (f, idx) => idx < startingIndex && 
                      f.status === UploadStatus.COMPLETED && 
                      f.filename === currentFile.filename
        );

        if (duplicateIndex !== -1) {
          const confirmed = await this.handleDuplicateFile(currentFile);
          if (!confirmed) {
            const index = this.uploadedFiles.indexOf(currentFile);
            if (index > -1) {
              this.uploadedFiles.splice(index, 1);
              this.uploadProgress.total--;
            }
            continue;
          }
        }

        this.updateFileStatus(currentFile, UploadStatus.LOADING);

        try {
          await this.validateAndUploadFile(currentFile);
        } catch (error) {
          console.error("Error processing file:", error);
        }
      }
    } catch (error) {
      console.error('Error in file processing:', error);
    } finally {
      this.isLoadingFiles = false;
    }
  }

  private async readJsonFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const content = JSON.parse(e.target.result);
          if (!this.isValidGeoJSON(content)) {
            this.messageService.add({
              severity: 'error',
              summary: this.translocoService.translate(
                'EXTERNAL_PAGE.INVALID_FILE'
              ),
              detail: this.translocoService.translate(
                'EXTERNAL_PAGE.INVALID_GEOJSON_FILE',
                { filename: file.name }
              ),
              life: 5000
            });
            reject(new Error(this.translocoService.translate(
              'EXTERNAL_PAGE.INVALID_GEOJSON_FILE',
              { filename: file.name }
            )));
            return;
          }
          resolve(content);
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: this.translocoService.translate(
              'EXTERNAL_PAGE.INVALID_FILE'
            ),
            detail: this.translocoService.translate(
              'EXTERNAL_PAGE.INVALID_JSON_FILE',
              { filename: file.name }
            ),
            life: 5000
          });
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  deleteFile(index: number) {
    const fileToDelete = this.uploadedFiles[index];
    this.confirmationService.confirm({
      message: `${this.translocoService.translate(
        "EXTERNAL_PAGE.DELETE_CONFIRMATION_MSG"
      )} ${fileToDelete.filename || fileToDelete.file.name}?`,
      header: this.translocoService.translate(
        "EXTERNAL_PAGE.DELETE_CONFIRMATION"
      ),
      accept: () => {
        this.uploadedFiles.splice(index, 1);
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  downloadFile(index: number) {
    const fileToDownload = this.uploadedFiles[index];
    if (fileToDownload) {
      window.open(fileToDownload.downloadUrl, '_blank');
    }
  }

  getFileSize(size: number): string {
    if (size < 1024) {
      return size + " B";
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + " KB";
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + " MB";
    } else {
      return (size / 1073741824).toFixed(2) + " GB";
    }
  }

  onNextStep() {
    this.currentStep++;
    if (this.currentStep === 1) {
      this.uploadedFiles.forEach(file => {
        file.showErrors = false;
      });
      this.validateAssets();
    }

    this.updateStepperStyles();
  }

  onPreviousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }

    this.updateStepperStyles();
  }

  isNextDisabled(): boolean {
    return this.currentStep === 1 || 
           (this.currentStep === 0 && 
            (!this.uploadedFiles.length || 
             this.uploadedFiles.some(file => file.status !== UploadStatus.COMPLETED)));
  }

  isBackDisabled(): boolean {
    return this.currentStep === 0 || 
           (this.currentStep === 1 && this.hasValidatingAssets());
  }

  private updateAssetStatus(
    asset: ValidatedAsset, 
    status: AssetValidationStatus, 
    error?: string, 
    assetUrl?: string
  ): void {
    asset.status = status;
    
    if (error) {
      asset.error = error;
    }
    
    if (assetUrl) {
      asset.assetUrl = assetUrl;
    }
  }

  async validateAssets() {
    const unvalidatedFiles = this.uploadedFiles.filter(file => 
      file.status === UploadStatus.COMPLETED && 
      !this.validatedAssets.some(asset => asset.filename === file.filename)
    );

    if (unvalidatedFiles.length === 0) return;

    this.validationProgress = {
      current: 0,
      total: unvalidatedFiles.length,
      successCount: 0
    };

    for (const file of unvalidatedFiles) {
      const asset: ValidatedAsset = {
        assetUrl: file.downloadUrl!,
        filename: file.filename || file.file.name,
        status: 'PENDING'
      };
      this.validatedAssets.push(asset);

      try {
        this.updateAssetStatus(asset, 'VALIDATING');
        this.validationProgress.current++;
        
        const response = await firstValueFrom(
          this.uploadService.validateAsset(asset.assetUrl, this.authService.tenantId || '', this.crdStateService.crd)
        );
        
        if (response.id) {
          await this.pollWorkflowStatus(response.id, asset);
          if (asset.status === 'COMPLETED') {
            this.validationProgress.successCount++;
          }
        } else {
          this.updateAssetStatus(asset, 'ERROR', this.translocoService.translate('EXTERNAL_PAGE.NO_JOB_ID'));
        }
      } catch (error: any) {
        this.updateAssetStatus(asset, 'ERROR', error.error?.message || this.translocoService.translate('EXTERNAL_PAGE.VALIDATION_FAILED'));
      }
    }
  }

  downloadValidatedAsset(asset: ValidatedAsset) {
    if (asset.assetUrl) {
      window.open(asset.assetUrl, '_blank');
    }
  }
  
  hasValidatingAssets(): boolean {
    return this.validatedAssets.some(asset => asset.status === 'VALIDATING');
  }

  getValidatedAsset(file: UploadedFile): ValidatedAsset | undefined {
    return this.validatedAssets.find(asset => 
      asset.filename === (file.filename || file.file.name)
    );
  }
}
