import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { PlantationPayload, PlantationUploadResponse } from 'src/app/models/plantation-upload.model';
import { environment } from 'src/environments/environment';

export interface AssetValidationResponse {
  id?: string;
  status?: string;
  error?: string;
}

export interface WorkflowStatusResponse {
  status: string;
  result?: {
    asset_uri: string;
    error?: string;
    completed: boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http: HttpClient,
  ) { }

  validateDataPlantation(payload: PlantationPayload) {
    return this.http
      .post<PlantationUploadResponse>(
        `${environment.CRD_API}v2/plantation-data/validate/`,
        { ...payload }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  validateAsset(asset: string, tenant: string, crd: string) {
    return this.http.post<AssetValidationResponse>(
      `${environment.CRD_API}v2/plantation-data/process/risks-legality?crd=${crd}`,
      { asset, tenant }
    );
  }

  getWorkflowStatus(jobId: string, crd: string) {
    return this.http.get<WorkflowStatusResponse>(
      `${environment.CRD_API}v2/executions/status?id=${jobId}&crd=${crd}`,
    );
  }
}
