import { TranslocoService } from '@jsverse/transloco';
import { lastValueFrom, of } from 'rxjs';
import { FirebaseService } from 'src/app/summary-dashboard/file/firebase.service';
import { AuthService } from './services/auth.service';
import { UserConfigService } from './services/user-config.service';
import { ActivatedRoute } from '@angular/router';

/**
 * A factory function returning a promise to preload the languages assets
 * before starting the Angular app, used as an APP_INITIALIZER provider
 * @param translocoService used to set active language and load language asset
 * @returns a promise resolving the translation asset.
 */
export function preloadLanguage(translocoService: TranslocoService) {
  return () => {
    const regex = /language\/([^\\/?\s]+)/;
    const match = window.location.href.match(regex);
    let language = 'engb';
    if (match && match.length > 1) {
      language = match[1];
    }
    translocoService.setActiveLang(language);
    return lastValueFrom(translocoService.load(language));
  };
}

export function preloadTheme(userConfigService: UserConfigService, authService: AuthService) {
  return () => {
    let tenantId = new URL(window.location.href).searchParams.get('tenantId');
    if (window.location.pathname.includes('/supplierportal')) {
      tenantId = authService.tenantId
    }
    if (tenantId) {
      userConfigService.setTheme(tenantId);
    }
    return of(true);
  };
}

export function initializeEnvironmentConfig(
  userConfigService: UserConfigService
) {
  return () => {
    const url = window.location.href.toLowerCase();
    const isCn = url.includes('agridencecn');
    if (isCn) {
      userConfigService.setCnEnvironmentConfig();
    }
    return of(true);
  };
}

export function preLoadToken(authService: AuthService, firebaseService: FirebaseService): () => Promise<boolean> {
  return async () => {
    const params = new URLSearchParams(window.location.search);
    const ftoken = params.get('ftoken');

    if (ftoken && (!authService.firebaseToken || authService.firebaseToken !== '') && !window.location.pathname.includes('/supplierportal')) {
      authService.firebaseToken = ftoken;

      try {
        const isSuccess = await firebaseService.signInWithCustomTokenFirebase(ftoken as string);
        return isSuccess;
      } catch (error) {
        console.error('Error during Firebase sign-in:', error);
        return false; 
      }
    }

    return false;
  };
}
