export const SETTINGS_LABELS = {
  confirmation: {
    header: 'DASHBOARD.SETTINGS_PAGE.CONFIRMATION_MODAL.HEADER',
    subHeader: 'DASHBOARD.SETTINGS_PAGE.CONFIRMATION_MODAL.SUB_HEADER',
    primaryAction: 'DASHBOARD.SETTINGS_PAGE.CONFIRMATION_MODAL.YES_PROCEED',
    secondaryAction:
      'DASHBOARD.SETTINGS_PAGE.CONFIRMATION_MODAL.BACK_TO_PREVIOUS'
  },
  redirect: {
    header: `We have detected that you have not done the polygon mapping configurations setup. Please go to the Polygon Mapping Settings from Polygon Mapping page to setup the configurations in order to view risk buffer data.`,
    primaryAction: 'Go to Polygon Mapping Settings',
    secondaryAction: 'Cancel'
  }
};
