import { UtilityService } from 'src/app/services/utility.service';

import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/compat/storage';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, last, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private uploadedFiles: any[] = [];

  constructor(
    private fireStorage: AngularFireStorage,
    private fireAuth: AngularFireAuth,
    private utilityService: UtilityService
  ) { }

  handleFilesFirebase(data: {
    files: FileList;
    bucketPath: string;
    outputPath?: boolean;
  }): Observable<string[]> {
    const uploadObservables: Observable<any>[] = [];

    for (let i = 0; i < data.files.length; i++) {
      const file = data.files[i];
      const fileName = file.name;
      const filePath = `${data.bucketPath}/${fileName}`;

      this.uploadedFiles.push({ file, loading: true });

      // Create an observable for each file upload process
      const task = this.uploadFileFirebase(file, filePath);

      const uploadObservable = task.snapshotChanges().pipe(
        last(), // Ensure the upload is completed
        switchMap(() => this.getDownloadLink(filePath, data?.outputPath)),
        switchMap((firebaseFileLink) => {
          // Return the payload as the result of this upload
          return of(firebaseFileLink);
        }),
        catchError((error) => {
          console.error('Upload error:', error);

          this.utilityService.emitToast({
            message: 'There was an error uploading the file. Please try again.',
            isSuccess: false
          });
          this.uploadedFiles.pop(); // Remove failed file from the array

          // Return `null` in case of an error to signify failure
          return of(null);
        })
      );

      uploadObservables.push(uploadObservable);
    }

    // Return an observable that emits an array of upload results (filtering out any null results)
    return forkJoin(uploadObservables).pipe(
      map((results) => results.filter((result) => result !== null))
    );
  }

  uploadFileFirebase(file: File, filePath: string): AngularFireUploadTask {
    const token = sessionStorage.getItem('ARP_FirebaseToken') || '';
    return this.fireStorage.upload(filePath, file, {
      customMetadata: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  getDownloadLink(filePath: string, outputPath = false) {
    if (outputPath) {
      const path = `${environment.firebase.storageBucket}/${filePath}`;
      return of(path.replace('gs://', ''));
    }

    return this.fireStorage.ref(filePath).getDownloadURL();
  }

  signInWithCustomTokenFirebase(token: string): Promise<boolean> {
    return this.fireAuth
      .signInWithCustomToken(token)
      .then((userCredential) => {
        return userCredential.user?.getIdTokenResult().then((idTokenResult) => {
          sessionStorage.setItem(
            'ARP_FirebaseAuthTokenExpiry',
            new Date(idTokenResult.expirationTime).toString()
          );
          return true; // Success
        }) ?? false; // Handle case where userCredential.user is undefined
      })
      .catch((error) => {
        console.error('Authentication error:', error);
        return false; // Failure
      });
  }

}
