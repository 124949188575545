import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  UserCustomConfig
} from '../models/user-custom-config.model';
import { from, map, catchError, EMPTY } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

interface UserStyle {
  colors: { [key: string]: string };
  headerLogo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  getUserConfig(tenantId: string) {
    const config = environment.USER_CONFIG.replace('{tenantId}', tenantId);
    return from(
      fetch(config)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then<UserCustomConfig>((data) => data)
    );
  }

  setTheme(tenantId: string) {
    this.getUserConfig(tenantId)
      .pipe(
        map((config) => ({
          colors: config.style.colors,
          headerLogo: config.headerLogo
        })),
        catchError((error) => {
          console.error('Error fetching user config:', error);
          if(window.location.pathname.includes('/supplierportal')){
            this.authService.tenantId = 'agridence';
            this.authService.tenantLogo = null;
            this.router.navigate(['/org/agridence/supplierportal']);
          }
          return EMPTY;
        })
      )
      .subscribe((style: UserStyle) => {
        for (const [key, value] of Object.entries(style.colors)) {
          this.setColor(this.formatToCssVar(key), value);
        }
        style.headerLogo && (this.authService.tenantLogo = style.headerLogo);
      });
  }

  setColor(property: string, value: string) {
    document.documentElement.style.setProperty(property, value);
  }

  formatToCssVar(str: string): string {
    return `--${str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`;
  }

  setCnEnvironmentConfig() {
    environment.CRD_API = environment.CRD_API_CN;
    environment.TRACEABILITY_API = environment.TRACEABILITY_API_CN;
  }
}
