import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
import { ScreenEnum } from '../enums/screens.enum';
import { MapPageComponent } from '../map-page/map-page.component';
import {
  INITIAL_SELECTED_PERIOD,
  SiDetails
} from '../models/crd-state.interface';
import { PrimengExportsModule } from '../primeng-exports.module';
import { AuthService } from '../services/auth.service';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { UtilityService } from '../services/utility.service';
import { SettingsPageComponent } from '../settings-page/settings-page.component';
import { SiLinkingPageComponent } from '../si-linking-page/si-linking-page.component';
import { SummaryDashboardComponent } from '../summary-dashboard/summary-dashboard.component';

export const routes: Route[] = [
  {
    path: '/',
    component: SummaryDashboardComponent
  }
];
@Component({
  selector: 'app-crd-shell',
  standalone: true,
  imports: [
    CommonModule,
    SummaryDashboardComponent,
    MapPageComponent,
    SettingsPageComponent,
    PrimengExportsModule,
    SiLinkingPageComponent,
    RouterModule
  ],
  templateUrl: './crd-shell.component.html',
  styleUrls: ['./crd-shell.component.scss']
})
export class CrdShellComponent implements OnInit, AfterViewChecked {
  @ViewChild('container', { static: false }) container!: ElementRef;
  get canAssignToken() {
    return (
      this.authService.userInfoToken === 'null' ||
      !this.authService.userInfoToken ||
      this.route.snapshot.params['token']
    );
  }
  screens = ScreenEnum;

  get hasRouteParams() {
    return (
      this.route.snapshot.params['crd'] &&
      this.route.snapshot.params['plantationTable']
    );
  }
  constructor(
    public crdStateService: CrdStateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utilityService: UtilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializeGlobal();
  }

  ngAfterViewChecked() {
    this.emitContentHeight();
  }

  initializeGlobal() {
    if (this.route.snapshot.params['token']) {
      this.initializeToken();
      this.initializeIsSiLinking();
      this.initializeApiParams();
      this.crdStateService.setSelectedPeriod(INITIAL_SELECTED_PERIOD);
      if (sessionStorage.getItem('dashboardTableState')) {
        sessionStorage.removeItem('dashboardTableState');
      }
    }
  }

  emitContentHeight() {
    const divHeight = this.container.nativeElement.offsetHeight;
    const remValue = divHeight / 14;
    this.utilityService.emitPropToParent('contentHeight', remValue);
  }

  initializeIsSiLinking() {
    this.route.queryParams.subscribe((params) => {
      if (params['siNumber']) {
        this.crdStateService.setSiDetails(params as SiDetails);
        this.crdStateService.setCurrentScreen(ScreenEnum.SI_LINKING);
        this.router.navigate(['si-linking'], { relativeTo: this.activatedRoute });
      }
    });
  }

  initializeApiParams() {
    this.crdStateService.getDatasets();
    const crd = this.route.snapshot.params['crd'];
    const plantationTable = this.route.snapshot.params['plantationTable'];

    if (this.hasRouteParams) {
      this.crdStateService.crd = crd;
      this.crdStateService.plantationTable = plantationTable;
      this.crdStateService.getRiskProperties();
      return;
    }

    this.crdStateService.datasets$.subscribe((res) => {
      if (res?.length) {
        this.crdStateService.crd = res[0].name;
        this.crdStateService.plantationTable = res[0].default_plantations_table;
        this.crdStateService.getRiskProperties();
      }
    });
  }

  initializeToken() {
    if (this.canAssignToken) {
      this.authService.userInfoToken =
        this.route.snapshot.params['token'] || null;
      this.authService.firebaseToken =
        this.route.snapshot.queryParams['ftoken'] || null;
    }
  }
}


