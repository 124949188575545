import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrdShellComponent } from './crd-shell/crd-shell.component';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: '', component: CrdShellComponent },
  {
    path: 'token/:token',
    children: [
      {
        path: 'language/:language',
        loadChildren: () =>
          import('./crd-shell/crd-shell.routes')
              .then(m => m.Routes)
      },
      {
        path: 'crd/:crd/plantation-table/:plantationTable',
        component: CrdShellComponent
      }
    ]
  },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', redirectTo: 'error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
