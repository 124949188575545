import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from '../services/auth.service';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { CrdStateService } from '../services/state-service/crd-state.service';
import { EventStateService } from '../services/state-service/event-state.service';

@Injectable()
export class CrdInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private crdStateService: CrdStateService,
    private eventStateService: EventStateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.eventStateService.hasError = false;
    const authToken = `Token ${this.authService.userInfoToken}`;

    // Determine if we need to remove headers and params
    const modifiedRequest = this.createModifiedRequest(request, authToken);

    if (modifiedRequest.headers.has('skip-error-interceptor')) {
      return next.handle(modifiedRequest);
    }

    return next
      .handle(modifiedRequest)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private createModifiedRequest(
    request: HttpRequest<unknown>,
    authToken: string
  ): HttpRequest<unknown> {
    if (request.headers.has('no-default-interceptor')) {
      return request.clone({
        headers: request.headers.delete('no-default-interceptor'),
        // params: new HttpParams(),  // Removes all query parameters
        setHeaders: { Authorization: authToken }
      });
    }

    const params = this.getModifiedParams(request.params);

    return request.clone({
      setHeaders: { Authorization: authToken },
      params: this.shouldClearParams(request) ? undefined : params
    });
  }

  private getModifiedParams(originalParams: HttpParams): HttpParams {
    return originalParams
      .set('crd', this.crdStateService.crd)
      .set('plantation_table', this.crdStateService.plantationTable);
  }

  // TODO: refactor - remove
  private shouldClearParams(request: HttpRequest<unknown>): boolean {
    return [
      'v1/dataset',
      'plantation-data/validate',
      'plantations/?crd',
      'file-upload/columns',
      'assets',
      'features',
      'plantation-data/process',
      request.url.includes('favorites') && request.method === 'POST'
    ].some((condition) => request.url.includes(condition as any));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.eventStateService.hasError = true;
    this.eventStateService.resetAllLoadingStates();

    if (
      error.status === 401 ||
      (error.status === 404 && error.message === 'Not found')
    ) {
      this.router.navigate(['error'], {
        queryParams: { status: error.status, detail: error.error.detail }
      });
      sessionStorage.removeItem('ARP_Token');
      sessionStorage.removeItem('ARP_FirebaseToken');
    } else {
      this.dialogService.open(ErrorPageComponent, {
        data: error
      });
    }

    return throwError(() => error.error.detail);
  }
}
