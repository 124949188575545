import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, map, Observable } from 'rxjs';
import { RiskDataByPlantationCode } from 'src/app/models/crd-state.interface';
import { RiskMitigationPayload } from 'src/app/models/risk-mitigation-payload.model';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';
import { formatDate } from '@angular/common';
import { UtilityService } from '../utility.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class RiskMitigationService {
  constructor(
    private http: HttpClient,
    private eventStateService: EventStateService,
    private utilityService: UtilityService,
    private translocoService: TranslocoService
  ) {}

  getRiskDataByPlantationCode(
    plantationCode: string,
    selectedPeriod: string
  ): Observable<RiskDataByPlantationCode> {
    const headers = new HttpHeaders({
      'Accept-Language': this.utilityService.getLanguageHeaderValue(
        this.translocoService.getActiveLang()
      )
    });

    this.eventStateService.isRiskDataByPlantationCodeLoading = true;
    return this.http
      .get<RiskDataByPlantationCode[]>(
        `${environment.CRD_API}v2/plantations/mitigations/risks?date=${selectedPeriod}&plantation_code=${plantationCode}`,
        { headers }
      )
      .pipe(
        map((res) => {
          const updatedData: RiskDataByPlantationCode[] = res.map((res) => ({
            ...res,
            risks: res.risks.map((risk) => ({
              ...risk,
              updated_date: risk?.updated_date
                ? formatDate(risk.updated_date, 'MM/dd/yyyy', 'en-us')
                : null,
              statusChipProps: risk?.status
                ? this.utilityService.getMitigationStatusChipProps(risk.status)
                : null
            }))
          }));

          /**
           *  Currently only returns first element.
           *  Adjust accordingly if requirement changes to accept multiple plantation codes
           */
          return updatedData[0];
        }),
        finalize(() => {
          this.eventStateService.isRiskDataByPlantationCodeLoading = false;
        })
      );
  }

  riskVerification(
    payload: RiskMitigationPayload
  ): Observable<{ message: string }> {
    return this.http
      .patch<{ message: string }>(
        `${environment.CRD_API}v2/mitigation/risks`,
        payload
      )
      .pipe(
        finalize(() => {
          this.eventStateService.refreshTable = true;
        })
      );
  }
}
