import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CrdInterceptor } from './interceptor/crd.interceptor';
import { PrimengExportsModule } from './primeng-exports.module';
import { MessageService } from 'primeng/api';
import { provideTransloco } from '@jsverse/transloco';
import { APP_INITIALIZERS, TRANSLOCO_CONFIG } from './app.config';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';


const firebaseConfig = [
  provideFirebaseApp(() => initializeApp(environment.firebase)), // TODO: provide envrionment for firebase
  provideFirestore(() => getFirestore()),
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule.enablePersistence(),
  AngularFireStorageModule
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PrimengExportsModule,
  ...firebaseConfig
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    DecimalPipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrdInterceptor,
      multi: true
    },
    // TODO: remove - use only transloco module or this
    provideTransloco(TRANSLOCO_CONFIG),
    APP_INITIALIZERS
  ]
})
export class AppModule {}
