<div class="flex flex-column px-4">
  <span class="my-3">{{ modalContent.content }} </span>

  <span>{{ modalContent?.subContent }}</span>

  <div class="flex do-not-show" *ngIf="screen === screens.DASHBOARD">
    <p-checkbox
      class="mr-2"
      [binary]="true"
      [(ngModel)]="isDoNotShowAgain"
    ></p-checkbox>
    <span> Do not show this popup again. </span>
  </div>
</div>

<div>
  <p-divider></p-divider>
</div>

<div class="flex justify-content-end px-4 pb-4">
  <p-button
    [disabled]="isConfirmInProgress"
    (onClick)="onBackClicked()"
    
    styleClass="p-button-outlined mr-3"
    >{{ modalContent.secondaryAction }}</p-button
  >
  <p-button
    [disabled]="isConfirmInProgress"
    (onClick)="onConfirmClicked()"
    styleClass="p-button"
    >{{ modalContent.primaryAction }}</p-button
  >
</div>
