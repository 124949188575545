import { ColumnDefinition } from '../models/column-definition.model';

export const PLANTATION_LIST_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PLANTATION_NAME',
    sortable: true,
    editable: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 2,
    field: 'plantation_code',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PLANTATION_CODE',
    sortable: true,
    editable: true,
    style: 'width: 12rem;'
  },
  {
    id: 3,
    field: 'risk_rating',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.RISK_RATING',
    editable: false,
    style: 'width: 11rem;'
  },
  {
    id: 4,
    field: 'mitigation_status',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.MITIGATION_STATUS',
    editable: false,
    style: 'width: 13rem;'
  },
  {
    id: 5,
    field: 'risks',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.GEOSPATIAL',
    editable: false,
    style: 'width: 10rem;'
  },
  {
    id: 6,
    field: 'average_monthly_yield',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.AVERAGE_YIELD',
    editable: false,
    style: 'width: 11rem;',
    sortable: true
  },
  {
    id: 7,
    field: 'planting_year',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PLANTING_YEAR',
    style: 'width: 11rem;',
    sortable: true
  },
  {
    id: 8,
    field: 'area_calculated',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.LAND_AREA',
    sortable: true,
    editable: true
  },
  {
    id: 9,
    field: 'country',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.COUNTRY',
    sortable: true
  },
  {
    id: 10,
    field: 'adm_1',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PROVINCE_STATE',
    sortable: true
  },
  {
    id: 11,
    field: 'adm_2',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DISTRICT',
    sortable: true
  },
  {
    id: 12,
    field: 'adm_3',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.SUB_DISTRICT',
    sortable: true
  },
  {
    id: 13,
    field: 'adm_4',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.VILLAGE',
    sortable: true
  },
  {
    id: 14,
    field: 'date_created',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DATE_CREATED',
    sortable: true,
    editable: true
  },
  {
    id: 15,
    field: 'data_source',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.DATA_SOURCE',
    sortable: true,
    editable: false,
    style: 'width: 15rem;'
  },
  {
    id: 16,
    field: 'field_team_code',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.PROJECT_CODE',
    sortable: false,
    editable: false
  },
  {
    id: 17,
    field: 'actions',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.ACTIONS',
    sortable: false,
    editable: false,
    style: 'width: 9rem;'
  }
];

export const PLANTATION_QUESTIONNAIRE_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.PLANTATION_NAME',
    sortable: true,
    filter: true,
    filterApiKey: '_geo_type',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 2,
    field: 'plantation_code',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.PLANTATION_CODE',
    sortable: true,
    filter: false,
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 3,
    field: 'risk_rating',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.RISK_RATING',
    sortable: false,
    filter: true,
    filterApiKey: 'risk_rating',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 12rem;'
  },
  {
    id: 4,
    field: 'mitigation_status',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.MITIGATION_STATUS',
    editable: false,
    filter: true,
    isText: true,
    style: 'width: 13rem;'
  },
  {
    id: 5,
    field: 'overAllSurveyMaxRiskRatingProps.risk_framework',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.RISK_FRAMEWORK',
    sortable: false,
    filter: false,
    filterApiKey: 'risk_framework',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 6,
    field: 'land_legality',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LAND_USE_RIGHTS',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 12rem'
  },
  {
    id: 7,
    field: 'environmental_protection',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.ENVIRONMENTAL_PROTECTION',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 16rem'
  },
  {
    id: 8,
    field: 'third_parties_FPIC',
    displayName:
      'DASHBOARD.QUESTIONNAIRE_COLUMNS.THIRD_PARTIES_RIGHTS_AND_FPIC',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 17rem'
  },
  {
    id: 9,
    field: 'labour_rights',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LABOUR_HUMAN_RIGHTS',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 12rem'
  },
  {
    id: 10,
    field: 'tax_anti_corruption',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.TAX_ANTI_CORRUPTION',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 15rem'
  },
  {
    id: 11,
    field: 'data_source',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.DATA_SOURCE',
    sortable: true,
    filter: true,
    filterApiKey: 'data_source',
    isText: true,
    editable: false,
    style: 'width: 14rem'
  },
  {
    id: 12,
    field: 'country',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.COUNTRY',
    sortable: true,
    filter: true,
    filterApiKey: 'country',
    isText: true,
    editable: false,
    style: 'width: 10rem'
  }
];

export const LEGALITY_QUESTIONNAIRE_TABLE_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'plantation_name',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.PLANTATION_NAME',
    sortable: true,
    filter: true,
    filterApiKey: '_geo_type',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 2,
    field: 'plantation_code',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.PLANTATION_CODE',
    sortable: true,
    filter: false,
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 3,
    field: 'risk_rating',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.RISK_RATING',
    sortable: false,
    filter: true,
    filterApiKey: 'risk_rating',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 12rem;'
  },
  {
    id: 4,
    field: 'mitigation_status',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.MITIGATION_STATUS',
    editable: false,
    filter: true,
    isText: true,
    style: 'width: 13rem;'
  },
  {
    id: 5,
    field: 'overAllSurveyMaxRiskRatingProps.risk_framework',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.RISK_FRAMEWORK',
    sortable: false,
    filter: false,
    filterApiKey: 'risk_framework',
    editable: false,
    isText: true,
    style: 'word-break: break-word; width: 15rem;'
  },
  {
    id: 6,
    field: 'land_legality',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LAND_USE_RIGHTS',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 12rem'
  },
  {
    id: 7,
    field: 'environmental_protection',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.ENVIRONMENTAL_PROTECTION',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 16rem'
  },
  {
    id: 8,
    field: 'third_parties_FPIC',
    displayName:
      'DASHBOARD.QUESTIONNAIRE_COLUMNS.THIRD_PARTIES_RIGHTS_AND_FPIC',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 17rem'
  },
  {
    id: 9,
    field: 'labour_rights',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LABOUR_HUMAN_RIGHTS',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 12rem'
  },
  {
    id: 10,
    field: 'tax_anti_corruption',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.TAX_ANTI_CORRUPTION',
    sortable: false,
    filter: false,
    filterApiKey: 'risks',
    isText: false,
    editable: false,
    style: 'width: 15rem'
  },
  {
    id: 11,
    field: 'data_source',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.DATA_SOURCE',
    sortable: true,
    filter: true,
    filterApiKey: 'data_source',
    isText: true,
    editable: false,
    style: 'width: 14rem'
  },
  {
    id: 12,
    field: 'country',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.COUNTRY',
    sortable: true,
    filter: true,
    filterApiKey: 'country',
    isText: true,
    editable: false,
    style: 'width: 10rem'
  },
  {
    id: 13,
    field: 'actions',
    displayName: 'DASHBOARD.PLANTATION_LIST_COLUMNS.ACTIONS',
    sortable: false,
    editable: false,
    style: 'width: 9rem;'
  }
];

export const SURVEY_RISKS_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'land_legality',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LAND_USE_RIGHTS',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 2,
    field: 'environmental_protection',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.ENVIRONMENTAL_PROTECTION',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 3,
    field: 'third_parties_FPIC',
    displayName:
      'DASHBOARD.QUESTIONNAIRE_COLUMNS.THIRD_PARTIES_RIGHTS_AND_FPIC',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 4,
    field: 'labour_rights',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.LABOUR_HUMAN_RIGHTS',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  },
  {
    id: 5,
    field: 'tax_anti_corruption',
    displayName: 'DASHBOARD.QUESTIONNAIRE_COLUMNS.TAX_ANTI_CORRUPTION',
    sortable: false,
    filter: false,
    isText: false,
    editable: false,
    style: 'width: 17.4%'
  }
];

export const RISK_VERIFICATION_COLUMNS: ColumnDefinition[] = [
  {
    id: 1,
    field: 'risk_display_name',
    displayName: 'DASHBOARD.RISK_VERIFICATION_MODAL.COLUMNS.RISK_IDENTIFIED'
  },
  {
    id: 2,
    field: 'risk_type',
    displayName: 'DASHBOARD.RISK_VERIFICATION_MODAL.COLUMNS.RISK_TYPE'
  },
  {
    id: 3,
    field: 'status',
    displayName: 'DASHBOARD.RISK_VERIFICATION_MODAL.COLUMNS.RISK_STATUS'
  },
  {
    id: 4,
    field: 'file_type',
    displayName: 'DASHBOARD.RISK_VERIFICATION_MODAL.COLUMNS.FILE_TYPE',
    style: 'width: 10rem'
  },
  {
    id: 5,
    field: 'updated_date',
    displayName: 'DASHBOARD.RISK_VERIFICATION_MODAL.COLUMNS.UPDATED_DATE'
  }
];

export const MANAGE_FAVORITE_FILTER_COLUMNS = [
  {
    id: 1,
    field: 'filter_name',
    displayName:
      'DASHBOARD.MANAGE_FAVORITE_FILTER_MODAL.COLUMNS.FAVORITE_FILTER_NAME'
  },
  {
    id: 2,
    field: 'actions',
    displayName: 'DASHBOARD.MANAGE_FAVORITE_FILTER_MODAL.COLUMNS.ACTIONS'
  }
];
