export enum UploadStatus {
    PENDING = "pending",
    LOADING = "loading",
    COMPLETED = "completed",
    ERROR = "error",
  }
  
  export interface ErrorCount {
    text: string;
    count: number;
  }
  
  export interface UploadedFile {
    file: File;
    status: UploadStatus;
    downloadUrl?: string;
    filename?: string;
    size?: string;
    error?: string;
    errorCounts?: ErrorCount[];
    showErrors?: boolean;
  }
  
  export interface UploadProgress {
    current: number;
    total: number;
    successCount: number;
  }
  
  export const DEFAULT_UPLOAD_PROGRESS: UploadProgress = {
    current: 0,
    total: 0,
    successCount: 0,
  };
  
  export type AssetValidationStatus =
    | "PENDING"
    | "VALIDATING"
    | "COMPLETED"
    | "ERROR";
  
  export interface ValidatedAsset {
    assetUrl: string;
    filename: string;
    status: AssetValidationStatus;
    error?: any;
  }
  
  export interface ValidationProgress {
    current: number;
    total: number;
    successCount: number;
  }
  
  export const DEFAULT_VALIDATION_PROGRESS: ValidationProgress = {
    current: 0,
    total: 0,
    successCount: 0,
  };
  