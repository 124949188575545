<div class="secret-key-page">
  <p-toolbar class="download-toolbar" [ngClass]="{'agridence-tenant': authService.tenantId === 'agridence'}">
    <div class="p-toolbar-group-left">
      <img class="tenant-logo" [src]="authService.tenantLogo || '/assets/images/logo-white.svg'" alt="agridence-logo" />
    </div>
    <div class="p-toolbar-group-right" *ngIf="nextPage">
      <i class="pi pi-sign-out logout-btn text-2xl" (click)="logout()"></i>
    </div>
  </p-toolbar>
  <div class="secret-key-component" *ngIf="!nextPage">
    <div
      class="flex justify-content-center align-items-center"
      style="height: 90vh; display: grid; grid-template-columns: 1fr auto 1fr"
    >
      <div></div>
      <div class="flex flex-column">
        <span class="text-xl">
          {{ "EXTERNAL_PAGE.SECRET_KEY" | transloco }}</span
        >
        <input
          pInputText
          type="text"
          placeholder="{{ 'EXTERNAL_PAGE.ENTER_SECRET_KEY' | transloco }}"
          [(ngModel)]="secretKey"
          class="secret-input mt-2 text-lg"
        />
        <button
          pButton
          class="confirm-btn mt-3 text-lg font-medium"
          (click)="checkValidity()"
          [disabled]="!secretKey || secretKey.trim() === ''"
        >
          <ng-container *ngIf="!nextPage; else loading">
            {{ "EXTERNAL_PAGE.CONFIRM" | transloco }}
          </ng-container>
          <ng-template #loading>
            <i class="pi pi-spin pi-spinner pr-1"></i>
            {{ "EXTERNAL_PAGE.LOADING" | transloco }}
          </ng-template>
        </button>
      </div>
    </div>
    <div class="footer flex justify-content-center">
      {{ "EXTERNAL_PAGE.POWERED_BY_AGRIDENCE" | transloco }}
    </div>
  </div>
  <div class="download-si-component" *ngIf="nextPage">
    <div class="m-6">
      <p-steps class="external-portal-stepper" [model]="items" [readonly]="true" [activeIndex]="currentStep"></p-steps>
      
      <div class="mt-4" [ngSwitch]="currentStep">
        <div *ngSwitchCase="0">
          <h2>{{ "EXTERNAL_PAGE.UPLOAD_FILE" | transloco }}</h2>
          <div class="container p-4 flex flex-column">
            <div class="flex flex-column my-1 pl-4">
              <span class="text-xl">{{
                "EXTERNAL_PAGE.ACCEPTED_FILE_TYPE" | transloco
              }}</span>
              <span class="text-xl"
                >{{ "EXTERNAL_PAGE.MAX_SIZE" | transloco
                }}<span class="si-number"></span
              ></span>
            </div>
            <div class="flex-grow-1 flex flex-column upload-section">
              <div
                class="flex justify-content-center align-items-center flex-column m-4"
              >
                <div
                  class="flex flex-column justify-content-center align-items-center w-full mb-2 drop-zone"
                  [ngClass]="{ disabled: !uploadFiles }"
                  (drop)="onDrop($event)"
                  (dragover)="onDragOver($event)"
                  (dragleave)="onDragLeave($event)"
                >
                  <button
                    pButton
                    type="button"
                    label="Browse File"
                    icon="pi pi-upload"
                    class=""
                    (click)="fileInput.click()"
                    [disabled]="!uploadFiles"
                  ></button>
                  <span class="pt-2">{{
                    "EXTERNAL_PAGE.DRAG_AND_DROP_FILES_HERE" | transloco
                  }}</span>
                  <input
                    type="file"
                    #fileInput
                    class="input-file"
                    (change)="onFileSelected($event)"
                    [disabled]="!uploadFiles"
                    accept=".geojson"
                    multiple
                  />
                </div>
              </div>
            </div>
            <div class="w-full flex-grow-1">
              <div
                class="flex justify-content-center align-items-center"
                *ngIf="isLoadingFiles"
              >
                <div class="upload-progress mb-2">
                  <i class="pi pi-spin pi-spinner mr-2"></i>
                  Uploading {{uploadProgress.current}} of {{uploadProgress.total}} files
                </div>
              </div>
              <div class="uploaded-files-container">
                <div
                  *ngFor="let uploadedFile of uploadedFiles; let i = index"
                  class="flex flex-column my-4 p-2"
                  [ngClass]="{
                    'pending-upload': uploadedFile.status === UploadStatus.PENDING,
                    'current-upload': uploadedFile.status === UploadStatus.LOADING
                  }"
                >
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                      <img
                        src="assets/images/upload_file.svg"
                        alt="Upload File"
                        class="eudr-upload outlined-icon"
                      />
                      <div class="flex flex-column ml-4">
                        <span class="font-medium pb-1">{{
                          uploadedFile.filename || uploadedFile.file.name
                        }}</span>
                        <span>
                          <ng-container *ngIf="uploadedFile.size">
                            {{ uploadedFile.size }} •
                          </ng-container>
                          <ng-container [ngSwitch]="uploadedFile.status">
                            <ng-container *ngSwitchCase="UploadStatus.PENDING">
                              {{ "EXTERNAL_PAGE.PENDING" | transloco }}
                            </ng-container>
                            <ng-container *ngSwitchCase="UploadStatus.LOADING">
                              {{ "EXTERNAL_PAGE.UPLOADING" | transloco }}
                            </ng-container>
                            <ng-container *ngSwitchCase="UploadStatus.COMPLETED">
                              {{ "EXTERNAL_PAGE.UPLOAD_COMPLETE" | transloco }}
                            </ng-container>
                            <ng-container *ngSwitchCase="UploadStatus.ERROR">
                              {{ uploadedFile.error || "EXTERNAL_PAGE.UPLOAD_ERROR" | transloco }}
                            </ng-container>
                          </ng-container>
                        </span>
                      </div>
                      <div class="ml-2 mb-4">
                        <i *ngIf="uploadedFile.errorCounts?.length" 
                          class="pi pi-exclamation-triangle text-red-500 mr-3 cursor-pointer"
                          (click)="uploadedFile.showErrors = !uploadedFile.showErrors"
                          pTooltip="{{ uploadedFile.errorCounts?.length }} validation errors"
                          tooltipPosition="right">
                        </i>
                      </div>
                    </div>
                    <ng-container *ngIf="uploadedFile.status === UploadStatus.LOADING; else actionButtons">
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-container>
                    <ng-template #actionButtons>
                      <div>
                        <span 
                          *ngIf="uploadedFile.status === UploadStatus.COMPLETED"
                          class="material-symbols-outlined download-file-btn mr-2" 
                          (click)="downloadFile(i)"
                        >
                          download
                        </span>
                        <span 
                          *ngIf="uploadedFile.status === UploadStatus.COMPLETED"
                          class="material-symbols-outlined delete-btn" 
                          (click)="deleteFile(i)"
                        >
                          delete
                        </span>
                      </div>
                    </ng-template>
                  </div>

                  <p-panel *ngIf="uploadedFile.errorCounts?.length && uploadedFile.showErrors"
                          [toggleable]="true"
                          [collapsed]="!uploadedFile.showErrors"
                          (onCollapse)="uploadedFile.showErrors = false"
                          styleClass="mt-2"
                          class="error-panel-container">
                    <ul class="list-none p-0 m-0 error-list">
                      <li *ngFor="let error of uploadedFile.errorCounts" class="mb-2">
                        <i class="pi pi-circle-fill text-red-500 mr-2" style="font-size: 0.5rem"></i>
                        <span>{{ error.text }} - </span> <strong> {{ error.count }} </strong>
                      </li>
                    </ul>
                  </p-panel>
                </div>
              </div>
            </div>
            <p-confirmDialog class="confirm-modal"></p-confirmDialog>
          </div>
        </div>
        
        <div *ngSwitchCase="1">
          <h2>{{ "EXTERNAL_PAGE.STEPS.LEGALITY_CHECKS" | transloco }}</h2>
          <div class="container p-4 flex flex-column">
            <div class="flex justify-content-center align-items-center" 
                *ngIf="hasValidatingAssets()">
              <div class="upload-progress mb-2">
                <i class="pi pi-spin pi-spinner mr-2"></i>
                Processing {{validationProgress.current}} of {{validationProgress.total}} files
              </div>
            </div>
            <div class="card">
              <div *ngFor="let file of uploadedFiles" 
                   class="flex justify-content-between align-items-center my-4 p-2 surface-border"
                   [ngClass]="{'pending-validate': !getValidatedAsset(file)}">
                <div class="flex align-items-center">
                  <img
                    src="assets/images/upload_file.svg"
                    alt="Upload File"
                    class="eudr-upload outlined-icon"
                  />
                  <div class="flex flex-column ml-4">
                    <span class="font-medium pb-1">{{ file.filename || file.file.name }}</span>
                    <span>
                      <ng-container *ngIf="getValidatedAsset(file) as asset; else pendingValidation">
                        <ng-container [ngSwitch]="asset.status">
                          <span *ngSwitchCase="'PENDING'" class="text-500">
                            {{ 'EXTERNAL_PAGE.PENDING' | transloco }}
                          </span>
                          <span *ngSwitchCase="'VALIDATING'" class="text-blue-500">
                            <i class="pi pi-spin pi-spinner mr-2"></i>
                            {{ 'EXTERNAL_PAGE.PROCESSING' | transloco }}
                          </span>
                          <span *ngSwitchCase="'COMPLETED'" class="text-green-500">
                            <i class="pi pi-check mr-2"></i>
                            {{ 'EXTERNAL_PAGE.UPLOAD_COMPLETE' | transloco }}
                          </span>
                          <span *ngSwitchCase="'ERROR'" class="text-red-500">
                            <i class="pi pi-times mr-2"></i>
                            {{ asset.error }}
                          </span>
                        </ng-container>
                      </ng-container>
                      <ng-template #pendingValidation>
                        <span class="text-500">{{ 'EXTERNAL_PAGE.PENDING' | transloco }}</span>
                      </ng-template>
                    </span>
                  </div>
                </div>
                <div>
                  <span 
                    *ngIf="getValidatedAsset(file)?.status === 'COMPLETED' && getValidatedAsset(file) as asset"
                    class="material-symbols-outlined download-file-btn" 
                    (click)="downloadValidatedAsset(asset)"
                  >
                    download
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div *ngSwitchCase="2">
          <div class="flex justify-content-center align-items-center p-5">
            
          </div>
        </div>
        
        <div *ngSwitchCase="3">
          <div class="flex justify-content-center align-items-center p-5">
            
          </div>
        </div>
      </div>
      <div class="stepper-footer flex flex-row justify-content-end gap-2 mt-4">
        <button pButton 
                label="Back" 
                class="p-button-outlined"
                [disabled]="isBackDisabled()"
                (click)="onPreviousStep()">
        </button>
        <button pButton 
                label="Next" 
                class="p-button-primary"
                [disabled]="isNextDisabled()"
                (click)="onNextStep()">
        </button>
      </div>
    </div>
  </div>
  <p-toast></p-toast>
</div>
