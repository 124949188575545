import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { distinctUntilChanged, map, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isFirebaseAuthTokenExpired$ = timer(0, 60000).pipe( // Check every 60 seconds
    map(() => this.isFirebaseAuthTokenExpired()),
    distinctUntilChanged() // Emit only if the expiration status changes
  );

  get userInfoToken() {
    return sessionStorage.getItem('ARP_Token');
  }

  set userInfoToken(token: string | null) {
    sessionStorage.setItem('ARP_Token', token || '');
  }

  get firebaseToken() {
    return sessionStorage.getItem('ARP_FirebaseToken');
  }

  set firebaseToken(token: string | null) {
    sessionStorage.setItem('ARP_FirebaseToken', token || '');
  }

  get firebaseAuthToken() {
    return sessionStorage.getItem('ARP_FirebaseAuthToken');
  }

  set firebaseAuthToken(token: string | null) {
    sessionStorage.setItem('ARP_FirebaseAuthToken', token || '');
  }

  constructor() {
    // TODO - add checking if embedded or standalone
    setInterval(() => {
      sessionStorage.removeItem('ARP_Token');
      sessionStorage.removeItem('ARP_FirebaseToken');
    }, 1000 * 60 * 60 * 8);
  }


  decodeToken(token: string): any {
    return jwt_decode<any>(token);
  }

  
  isFirebaseAuthTokenExpired() {
    const firebaseAuthTokenExpiry = sessionStorage.getItem('ARP_FirebaseAuthTokenExpiry');

    const currentTime = new Date().getTime();

    return !firebaseAuthTokenExpiry || currentTime >= Number(firebaseAuthTokenExpiry);
  }

}
