import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-summary-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PageHeaderComponent,
    TabViewModule,
    TranslocoPipe
  ],
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.scss']
})
export class SummaryDashboardComponent {
  tabs = [
    {
      title: 'DASHBOARD.PLANTATIONS',
      content: 'Plantations',
      index: 0
    },
    {
      title: 'DASHBOARD.LEGALITY',
      content: 'Legality',
      index: 1
    }
  ]


  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

  }

  onTabChange(event: TabViewChangeEvent): void {

    let route = null;
    if(event.index === 0) {
      route = 'plantations';
    } else if(event.index === 1) { 
      route = 'legality';
    }

    this.router.navigate([route], {relativeTo: this.activatedRoute});
  }
}
