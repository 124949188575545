import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, map, of, tap } from 'rxjs';
import {
  RiskLayer,
  RiskValueAggregate
} from 'src/app/models/crd-state.interface';
import { DEFAULT_MAP_STYLE } from 'src/app/constants/map.const';
import { environment } from 'src/environments/environment';
import {
  GeometryPayload,
  RiskValueAggregatePayload
} from 'src/app/models/geometry-payload.model';
import { PagedResponse } from 'src/app/models/paged-response.model';
import { RiskValueTableParams } from 'src/app/models/table-params.model';
import { EventStateService } from '../state-service/event-state.service';

@Injectable({
  providedIn: 'root'
})
export class MapPageService {
  constructor(
    private http: HttpClient,
    private eventStateService: EventStateService
  ) {}

  getRiskLayers(): Observable<RiskLayer[]> {
    return this.http
      .get(`${environment.CRD_API}v1/getLayersForCompany/`)
      .pipe(
        map((res: any) => {
          const layers = Object.values(res.layers).map((layer: any) => ({
            ...layer,
            checked: false
          }));
          return Object.values(layers);
        })
      )
      .pipe(delay(2000));
  }

  getGeometryData(
    params: GeometryPayload,
    isSiLinking = false
  ): Observable<any> {
    this.eventStateService.isGeometryDataLoading = true;
    const geoQuery = isSiLinking
      ? 'ST_ASGEOJSON(_geo) as _geo'
      : 'ST_ASGEOJSON(IFNULL(risk_buffer_geo, _geo)) as _geo';
    const body = {
      columns: {
        schema_version: 'v1',
        columns: [geoQuery, 'plantation_code']
      },
      AND: {
        plantation_code: params.plantationCodes
      },
      OR: {}
    };

    return this.http
      .post<any>(
        `${environment.CRD_API}v2/plantations/?date=${params.period}&page=1&page_size=10000`,
        body
      )
      .pipe(
        map((geometry) => {
          const parsedGeometry = geometry.results.map((geo: any) => {
            return {
              ...geo,
              _geo: JSON.parse(geo._geo as string)
            };
          });
          return parsedGeometry;
        }),
        tap(() => {
          this.eventStateService.isGeometryDataLoading = false;
        })
      );
  }

  getMapStyle() {
    return of(DEFAULT_MAP_STYLE).pipe(delay(2000));
  }

  getPagedRiskValues(params: RiskValueTableParams) {
    const orderBy = params?.orderBy ? `&order_by=${params.orderBy}` : '';

    const body = {
      columns: {
        schema_version: 'v1',
        columns: [
          `${params.riskType}_overlap_area as area`,
          `round(${params.riskType}_overlap_area_perc, 6)  as percentage`,
          'plantation_name',
          'plantation_code'
        ]
      },
      AND: {
        ...this.getPlantationCodeQuery(params.plantationCodes),
        [`${params.riskType}_value`]: [true]
      },
      OR: {}
    };

    return this.http.post<PagedResponse<any>>(
      `${environment.CRD_API}v2/plantations/?date=${params.period}&page=${params.page}&page_size=${params.pageSize}${orderBy}`,
      body
    );
  }

  getRiskValueAggregates(
    params: RiskValueAggregatePayload
  ): Observable<RiskValueAggregate[]> {
    const body = {
      aggregates: [
        {
          key: `${params.riskType}_overlap_area`,
          function: 'sum',
          alias: 'area_calculated_sum'
        },
        {
          key: 'plantation_code',
          function: 'count'
        }
      ],
      AND: {
        ...this.getPlantationCodeQuery(params.plantationCodes),
        [`${params.riskType}_value`]: [true]
      },
      OR: {}
    };

    return this.http.post<RiskValueAggregate[]>(
      `${environment.CRD_API}v2/statistics/dynamic/stats/?date=${params.period}`,
      body
    );
  }

  getPlantationCodeQuery(plantationCodes: string[]) {
    // empty plantation used to return nothing
    return plantationCodes.length
      ? { plantation_code: plantationCodes }
      : { plantation_code: ['empty plantation'] };
  }
}
