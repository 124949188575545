import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Settings, SettingsPayload } from 'src/app/models/settings.model';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    private eventStateService: EventStateService
  ) {}

  getSettings(dataset: string): Observable<Settings[]> {
    this.eventStateService.isSettingsLoading = true;
    return this.http.get<Settings[]>(
      `${environment.CRD_API}v2/settings/?dataset=${dataset}`
    );
  }

  saveSettings(payload: SettingsPayload) {
    return this.http.post(`${environment.CRD_API}v2/settings/`, payload);
  }
}
