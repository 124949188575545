import { TranslocoService } from '@jsverse/transloco';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ToastProps } from '../models/toast.model';
import { DialogService } from 'primeng/dynamicdialog';
import { ScreenEnum } from '../enums/screens.enum';
import { SettingsModalComponent } from '../settings-page/settings-modal/settings-modal.component';
import { SettingsModalHeadersEnum } from '../enums/settings-modal-headers.enum';
import { GeoJsonTypesEnum } from '../enums/geojson-types.enum';
import { PostMessageTypesEnum } from '../enums/post-message-type.enum';
import { RiskRating } from '../models/crd-state.interface';
import { RiskRatingsEnum } from '../enums/risk-ratings.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private dialogService: DialogService,
    private translocoService: TranslocoService
  ) {}

  getPlantationCodesArray(plantationList: any[], plantationCodeProp: string) {
    const plantationCodes = new Set<string>();
    plantationList.forEach((plantation) => {
      plantationCodes.add(plantation[plantationCodeProp]);
    });
    return Array.from(plantationCodes);
  }

  constructMultiplePlantationsQuery(plantationCodes: string[]) {
    let queryParams = '';
    plantationCodes.forEach((code, index) => {
      if (index === 0) {
        queryParams += `?plantation_code=${code}`;
      } else {
        queryParams += `&plantation_code=${code}`;
      }
    });

    return queryParams;
  }

  getFileNameFromHeaders(response: HttpResponse<Blob>) {
    const contentDisposition =
      response.headers.get('content-disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let fileName = 'download';
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    return fileName;
  }

  emitPropToParent<T, U>(key1: string, value1: T, key2?: string, value2?: U) {
    const message = {
      ...(key1 && { [key1]: value1 }),
      ...(key2 && { [key2]: value2 })
    };

    window.parent.postMessage(message, '*');
  }

  emitToast(toast: ToastProps) {
    this.emitPropToParent('type', PostMessageTypesEnum.TOAST, 'toast', toast);
  }

  openSettingsModal(header: SettingsModalHeadersEnum, screen: ScreenEnum) {
    this.dialogService.open(SettingsModalComponent, {
      header: header,
      width: '45rem',
      data: screen,
      contentStyle: { padding: 0 },
      styleClass: 'settings-modal'
    });
  }

  getComputedStyle(value: string) {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(value)
      .trim();
  }

  getGeoJsonTypeProps(value: GeoJsonTypesEnum) {
    const props = {
      label: '',
      icon: ''
    };
    switch (value?.toLowerCase() || '') {
      case GeoJsonTypesEnum.ST_MULTIPOLYGON:
        props.label = 'Multi Polygon';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_POLYGON:
        props.label = 'Polygon';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_GEOMETRY_COLLECTION:
        props.label = 'Geometry Collection';
        props.icon = 'layers';
        break;
      case GeoJsonTypesEnum.ST_POINT:
        props.label = 'Point';
        props.icon = 'location_on';
        break;

      case GeoJsonTypesEnum.OTHERS:
        props.label = 'Others';
        props.icon = 'more_horiz';
        break;
      case GeoJsonTypesEnum.INVALID_GEOMETRY:
        props.label = 'Invalid Geometry';
        props.icon = 'warning';
        break;
      default:
        props.label = 'Invalid Geometry';
        props.icon = 'warning';
        break;
    }

    return props;
  }


  getRiskScoreProps(risk: {risk_score: number} | any) {
    const props = {
      label: '',
      color: '',
    };

    if (risk?.risk_score == 1) {
      props.label = 'Non-compliant';
      props.color = '#FF5449';
      return props;

      // return '#FF5449'; // red - high risk
    } else if (risk?.risk_score === 0) {
      props.label = 'compliant';
      props.color = '#72DD70';
      return props;

      //return '#72DD70'; // light green - low risk risk
    } else if (risk?.risk_score === -1) {
      props.label = 'No Risk';
      props.color = '#2DA63D';
      return props;

      // return '#2DA63D'; // green green - no risk
    } else {
      props.label = 'No Data';
      props.color = '#C6C6D0';
      return props;
      //return '#C6C6D0'; // gray - null / undefined
    }
  }

  getComplianceProps(compliance: RiskRatingsEnum): RiskRating {
    let label = '';
    let styleClass = '';

    switch (compliance) {
      case RiskRatingsEnum.COMPLIANT:
        label = 'DASHBOARD.COMPLIANT';
        styleClass = 'compliant';
        break;
      case RiskRatingsEnum.NON_COMPLIANT:
        label = 'DASHBOARD.NON_COMPLIANT';
        styleClass = 'non-compliant';
        break;
      case RiskRatingsEnum.NO_DATA:
        label = 'DASHBOARD.NO_DATA';
        styleClass = 'no-data';
        break;
      default:
        break;
    }

    return {
      compliance: label,
      styleClass: styleClass
    };
  }

  getRiskRatingFilterValue(value: string) {
    switch (value) {
      case this.translocoService.translate('DASHBOARD.COMPLIANT'):
        return RiskRatingsEnum.COMPLIANT;
      case this.translocoService.translate('DASHBOARD.NON_COMPLIANT'):
        return RiskRatingsEnum.NON_COMPLIANT;
      case this.translocoService.translate('DASHBOARD.NO_DATA'):
        return RiskRatingsEnum.NO_DATA;
      default:
        return '';
    }
  }

  darkenColor(color: string, percent: number): string {
    const num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
  
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }
}
